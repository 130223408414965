import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3.125rem;
  height: 100%;
  background-color: ${({ theme }) => theme.StateNormal};
  border-right: 1px solid ${({ theme }) => theme.LineMain};
  padding-bottom: 0.55rem;
  flex-shrink: 0;
`;

export const ItemWrapper = styled.div`
  position: relative;
  margin: 0.625rem 0;
  cursor: pointer;
  z-index: 5;
`;

export const HomeItemWrapper = styled(ItemWrapper)`
  margin: 0.813rem 0 0.75rem;
`;

export const HorizontalBar = styled.div`
  width: ${({ width }) => width}rem;
  margin-bottom: 0.625rem;
  border-bottom: 1px solid ${({ theme }) => theme.LineMain};
`;

export const UnreadCount = styled.span`
  position: absolute;
  top: -0.204rem;
  left: 0.763rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0.688rem;
  height: 0.688rem;
  padding: 0 0.194rem;
  border-radius: 0.5625rem;
  font-size: 0.469rem;
  color: #fff;
  background-color: #dc4547;
`;
