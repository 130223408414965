import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useCoreStores } from 'teespace-core';
import styled from 'styled-components';
import {
  ContentItem,
  ItemTitle,
  ItemInfo,
} from '../../../styles/usersettings/ContentAccountStyle';

const MyInvitationCount = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { myDomainSetting },
  } = useCoreStores();

  const beginDate = myDomainSetting.eventBeginDate?.replace(/\//g, '.');
  const endDate = myDomainSetting.eventEndDate?.replace(/\//g, '.');

  return (
    <>
      <ContentItem>
        <ItemTitle>
          {t('CM_SETTING_INVITE_CODE_06')}
          {beginDate && (
            <EventDate>
              ({beginDate} ~ {endDate})
            </EventDate>
          )}
        </ItemTitle>
        <ItemInfo>
          {beginDate ? myDomainSetting.inviteCount || 0 : '-'}
        </ItemInfo>
      </ContentItem>
      {!beginDate && (
        <EventItem>
          <ItemTitle />
          <EventMsg>{t('CM_SETTING_INVITE_CODE_10')}</EventMsg>
        </EventItem>
      )}
    </>
  );
});

export default React.memo(MyInvitationCount);

const EventDate = styled.div`
  font-size: 0.73rem;
  color: ${({ theme }) => theme.TextSub2};
  line-height: 0.75rem;
`;

const EventItem = styled.div`
  display: flex;
`;

const EventMsg = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.TextSub2};
`;
