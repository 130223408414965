import { Children } from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  width: 16.19rem;
  height: 100%;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 1px solid ${props => props.theme.LineMain};
`;

export const CustomTabs = styled(Tabs)`
  margin-top: 0.125rem;
  &.ant-tabs {
    width: 100%;
    height: calc(100% - 3.26rem);
  }
  .ant-tabs-content-holder {
    height: 100%;
  }
  .ant-tabs-content {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .ant-tabs-nav {
    margin: 0 0.75rem;
    &:before {
      border-color: ${props => props.theme.LineSub};
      z-index: 5;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
  }
  .ant-tabs-nav-list {
    flex: 1;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab {
    width: ${({ children }) =>
      `calc(100% / ${Children.toArray(children).length})`};
    justify-content: center;
    margin: 0;
    padding: 0;
    font-size: 0;
    &.ant-tabs-tab-active {
      span {
        color: ${props => props.theme.TextMain};
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 0.188rem;
        border-radius: 0.094rem;
        background-color: ${props => props.theme.CoreNormal};
      }
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${props => props.theme.TextMain};
      text-shadow: none;
    }
    &:hover:not(.ant-tabs-tab-active) {
      .lnb__icon-wrapper {
        background-color: ${props => props.theme.LnbHover};
      }
    }
  }

  .ant-tabs-tab-active .lnb__icon-wrapper {
    background-color: ${props => props.theme.LnbActive};
  }
  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    height: 100%;
  }
`;

export const UnreadCount = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  left: calc(100% + 0.375rem);
  transform: translateY(-50%);
  height: 0.875rem;
  padding: 0 0.25rem;
  font-size: 0.625rem;
  color: #fff;
  line-height: 0.875rem;
  font-weight: 400;
  border-radius: 0.56rem;
  background-color: #dc4547;
  text-align: center;
  flex-shrink: 0;
`;

export const TabWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.25rem;
`;

export const TabItemText = styled.span`
  font-size: 0.75rem;
  line-height: normal;
  color: ${props => props.theme.TextSub};
`;
