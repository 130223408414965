import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCoreStores, EventBus, AlarmSetting, WWMS } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { isDarkMode } from '../utils/GeneralUtil';
import { handleProfileMenuClick } from '../utils/ProfileUtil';
import openRoomModal from '../utils/OpenRoomUtil';
import { openMeeting } from '../utils/RoomUtil';

const useInitialize = () => {
  const {
    spaceStore,
    roomStore,
    userStore,
    friendStore,
    themeStore,
    authStore,
  } = useCoreStores();
  const [isLoaded, setIsLoaded] = useState(false);

  const { i18n } = useTranslation();
  const { resourceType, resourceId } = useParams();
  const history = useHistory();

  const url = window.location.origin; //  http://xxx.dev.teespace.net
  const conURL = url.split(`//`)[1]; // xxx.dev.teespace.net
  const mainURL = conURL.slice(conURL.indexOf('.') + 1, conURL.length); // dev.teespace.net

  /**
   * /f/:userId?action=talk
   * /f/:userId?action=meeting
   * 위 주소로 각각 1:1 talk, 1:1 meeting 을 호출할 수 있음.
   */
  const query = new URLSearchParams(window.location.search);
  const profileAction = query.get('action'); // profile 용 action

  let domainName;
  [domainName] = url.split(`//`)[1].split(`.`);

  const myUserId = userStore.myProfile.id;
  const isLocal = process.env.REACT_APP_ENV === 'local';

  const handleMoveTalk = roomInfo => history.push(`/s/${roomInfo.id}/talk`);

  const handleMoveMeeting = roomInfo => {
    openMeeting(roomInfo);
    handleMoveTalk(roomInfo);
  };

  const handleTalkClick = async () => {
    handleProfileMenuClick(
      myUserId,
      resourceId,
      handleMoveTalk,
      handleMoveTalk,
    );
  };
  const handleMeetingClick = async () => {
    handleProfileMenuClick(
      myUserId,
      resourceId,
      handleMoveMeeting,
      handleMoveMeeting,
    );
  };

  // 접근이 불가능할 경우 내 프로필로 이동
  // const handleMissingHistory = (found, target) => {
  //   if (!found) history.push(`/f/${myUserId}/profile`);
  //   else history.push(target);
  // };

  const handleLastHistory = () => {
    if (resourceType === 'f' && resourceId === myUserId)
      history.push(`/s/${roomStore.myRoom.id}/talk`);
  };

  const handleError = () => {
    if (isLocal) {
      setTimeout(() => {
        history.push('/logout');
      }, 1000);
    } else
      window.location.href = `${window.location.protocol}//${mainURL}/domain/${domainName}`;
  };

  useEffect(() => {
    // 휴면 계정인 경우 홈으로 이동
    if (userStore.myProfile.useCode === 'USE0002') {
      window.location.href = `${window.location.protocol}//${mainURL}/spaces`;
    }

    // 룸, 유저 프로필, 프렌즈, 알림 설정, 언어/테마 설정
    const promises = [
      roomStore.fetchRoomList(),
      userStore.fetchRoomUserProfileList({}),
      friendStore.fetchFriends(),
      userStore.getAlarmList(myUserId),
      userStore.getMyDomainSetting(),
    ];

    // 첫 로그인이 아니면 현재 그룹만 조회하자
    promises[promises.length] =
      authStore.sessionInfo?.isFirstLogin || resourceType === 'mail'
        ? spaceStore.fetchSpaces({
            userId: myUserId,
            isLocal,
          })
        : spaceStore.getDomainInfo({
            domainKey: authStore.sessionInfo.domainKey,
          });

    Promise.all(promises)
      .then(async res => {
        // roomStore fetch 후에 Talk init 하자 (lastMessage, unreadCount, ...)
        EventBus.dispatch('Platform:initLNB');
        // 알람 리스트 적용
        const [, , , alarmList] = res;
        AlarmSetting.initAlarmSet(alarmList);

        // 계정 langauge 적용. 없으면 브라우저 기본 langauge로 업데이트 한다.
        if (!userStore.myDomainSetting.language) {
          await userStore.updateMyDomainSetting({
            language: i18n.language,
          });
        } else i18n.changeLanguage(userStore.myDomainSetting.language);

        // 기본 테마 설정
        const platformTheme = userStore.myDomainSetting.theme;
        if (platformTheme && platformTheme !== 'system')
          themeStore.setTheme(platformTheme);
        else if (isDarkMode()) themeStore.setTheme('dark');

        // 스페이스 화면에서 1:1 Talk나 1:1 Meeting을 선택한 경우
        if (resourceType === 'f' && profileAction) {
          switch (profileAction) {
            case 'talk':
              handleTalkClick();
              break;
            case 'meeting':
              handleMeetingClick();
              break;
            default:
              break;
          }
        }

        if (resourceType === 's' && resourceId) {
          await roomStore.fetchOpenRoomList();
          const openRoom = roomStore.getOpenRoomMap().get(resourceId);
          if (openRoom) {
            // 오픈룸 입장관련 함수 호출
            handleLastHistory();
            openRoomModal({ openRoom, history });
          } else if (!roomStore.getRoom(resourceId)) {
            const groupName = url.split(`//`)[0];
            window.location.href = `${window.location.protocol}//${mainURL}/domain/${groupName}`;
          }
        } else handleLastHistory();
      })
      .then(() => {
        setIsLoaded(true);
      })
      .then(() => {
        if (!authStore.sessionInfo?.isFirstLogin && resourceType !== 'mail')
          spaceStore.fetchSpaces({
            userId: myUserId,
            isLocal,
          });
      })
      .catch(() => {
        handleError();
      });

    // NOTE : RECONNECT 임시 처리
    WWMS.setOnReconnect(() => {
      Promise.all([
        roomStore.fetchRoomList(),
        spaceStore.fetchSpaces({
          userId: myUserId,
          isLocal,
        }),
        friendStore.fetchFriends(),
      ])
        .then(() => {
          // NOTE: 이벤트명은 core에서 불릴 것 같지만, 플랫폼에서 불러줌
          EventBus.dispatch('Platform:reconnectWebSocket');
        })
        .catch(err => {
          console.log(err);
        });
    });
  }, []);

  return isLoaded;
};

export default useInitialize;
