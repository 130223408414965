import React, { useState, useCallback, useContext } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
  useCoreStores,
  Toast,
  ProfileInfoModal,
  ProfileModal,
  StatusSelector,
  EventBus,
} from 'teespace-core';
import { useHistory } from 'react-router-dom';
import { useObserver, Observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import GroupEditModal from './group/GroupEditModal';
import SettingDialog from '../usersettings/SettingDialog';
import MovePage from '../../utils/MovePage';
import { getLanguage } from '../../utils/GeneralUtil';
import * as useCommand from '../../hook/Command';
import { ArrowRightIcon, LanguageIcon } from '../Icons';
import {
  UserImage,
  UserName,
  UserMail,
  UserStatus,
  UserButtonBox,
  UserBar,
  LogoutButton,
  UserSubArea,
  LanguageButton,
  UserSettingArea,
  SettingButton,
  SettingBar,
  LanguageIconWrap,
  StatusSelectorWrapper,
} from '../../styles/profile/ProfileMyModalStyle';

const ProfileMyModal = ({ onCancel, visible = false }) => {
  const { t, i18n } = useTranslation();
  const { userStore, configStore } = useCoreStores();
  const history = useHistory();

  const [settingDialogVisible, setSettingDialogVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastText, setToastText] = useState('');

  const [isGroupEditModalVisible, setIsGroupEditModalVisible] = useState(false);

  const { myProfile } = userStore;
  const myUserId = myProfile.id;
  const thumbPhoto = userStore.getProfilePhotoURL(myUserId, 'medium');

  const themeContext = useContext(ThemeContext);

  const handleSettingDialogOpen = useCallback(() => {
    setSettingDialogVisible(true);
  }, []);

  const handleCloseSettingDialog = useCallback(() => {
    setSettingDialogVisible(false);
    setIsEditPassword(false);
  }, []);

  const toggleEditMode = useCallback(() => {
    setIsEditMode(i => !i);
  }, []);

  const handleLogout = useCallback(async () => {
    // Close dialog first
    if (onCancel) onCancel();
    history.push('/logout');
  }, [history, onCancel]);

  const revokeURL = useCallback(() => {
    URL.revokeObjectURL(thumbPhoto);
  }, [thumbPhoto]);

  const handleCancel = useCallback(() => {
    setTimeout(() => onCancel(), 1);
  }, [onCancel]);

  const handleEditPassword = useCallback(() => {
    setIsEditPassword(true);
    handleSettingDialogOpen();
  }, [handleSettingDialogOpen]);

  const handleOpenSupport = useCallback(() => {
    MovePage('support', true);
  }, []);

  const getBackPhoto = () => {
    return userStore.getBackgroundPhotoURL(myUserId);
  };

  const handleChangeLanguage = async language => {
    const result = await userStore.updateMyDomainSetting({ language });
    if (result) {
      i18n.changeLanguage(language).then((t, err) => {
        if (err) return console.log(`error is..${err}`);
        EventBus.dispatch('Platform:languageChanged');
      });
    }
  };

  const LanguageMenu = (
    <Menu style={{ minWidth: '6.25rem' }}>
      <Menu.Item key="ko" onClick={() => handleChangeLanguage('ko')}>
        {t('CM_KOREAN')}
      </Menu.Item>
      <Menu.Item key="en" onClick={() => handleChangeLanguage('en')}>
        {t('CM_ENGLISH')}
      </Menu.Item>
    </Menu>
  );

  const userContent = !isEditMode ? (
    <>
      <StatusSelectorWrapper>
        <StatusSelector selectable userId={myProfile.id} />
      </StatusSelectorWrapper>
      <UserImage>
        <img src={thumbPhoto} onLoad={revokeURL} alt="" />
      </UserImage>
      <UserName>{myProfile.displayName}</UserName>
      <UserMail>{`(${myProfile.loginId})`}</UserMail>
      {myProfile.profileStatusMsg && (
        <UserStatus>{myProfile.profileStatusMsg}</UserStatus>
      )}

      <UserButtonBox>
        <Button
          className="header-profile__edit-button"
          type="link"
          onClick={toggleEditMode}
        >
          {t('CM_EDIT_PROFILE')}
        </Button>

        {configStore.isActivateComponent('Platform', 'ChangePassword') ? (
          <>
            <UserBar />
            <Button
              className="header-profile__password-button"
              type="link"
              onClick={handleEditPassword}
            >
              {t('CM_PROFILE_MENU_08')}
            </Button>
          </>
        ) : null}
      </UserButtonBox>
      <LogoutButton
        className="header-profile__logout-button"
        onClick={handleLogout}
      >
        {t('CM_LOGOUT_01')}
      </LogoutButton>
    </>
  ) : (
    <ProfileInfoModal
      userId={myUserId}
      visible={isEditMode}
      onClose={toggleEditMode}
      profilePhoto={thumbPhoto}
      editMode
    />
  );

  const subContent = (
    <>
      <Observer>
        {() => (
          <UserSubArea>
            <LanguageIconWrap>
              <LanguageIcon
                width={1.25}
                height={1.25}
                color={themeContext.IconNormal}
              />
            </LanguageIconWrap>
            {t('CM_PROFILE_MENU_02', {
              language: getLanguage()?.includes('ko')
                ? t('CM_KOREAN')
                : t('CM_ENGLISH'),
            })}
            <Dropdown
              trigger={['click']}
              overlay={LanguageMenu}
              placement="bottomRight"
            >
              <LanguageButton className="header-profile__language-button">
                <ArrowRightIcon color={themeContext.IconNormal} />
              </LanguageButton>
            </Dropdown>
          </UserSubArea>
        )}
      </Observer>
      {isGroupEditModalVisible && (
        <GroupEditModal
          onClose={() => setIsGroupEditModalVisible(false)}
          onSuccess={() => {
            setToastText(t('CM_CHANGE_SAVE'));
            setIsToastOpen(true);
          }}
        />
      )}
      <Toast
        visible={isToastOpen}
        timeoutMs={1000}
        onClose={() => setIsToastOpen(false)}
      >
        {toastText}
      </Toast>
    </>
  );

  useCommand.Setting(handleSettingDialogOpen);
  useCommand.Logout(handleLogout);
  useCommand.Help(handleOpenSupport);

  return useObserver(() => (
    <>
      {visible ? (
        <ProfileModal
          visible={visible}
          mask={false}
          maskClosable
          onCancel={handleCancel}
          closable={false}
          outLine
          backgroundPhotoURL={getBackPhoto()}
          width="17.5rem"
          type="user"
          userContent={userContent}
          subContent={subContent}
          footer={
            <UserSettingArea>
              <SettingButton type="text" onClick={handleSettingDialogOpen}>
                {t('CM_SETTING')}
              </SettingButton>
              {!configStore.isFromCNU ? (
                <>
                  <SettingBar />
                  <SettingButton type="text" onClick={handleOpenSupport}>
                    {t('CM_PROFILE_MENU_04')}
                  </SettingButton>
                </>
              ) : null}
            </UserSettingArea>
          }
          transitionName=""
          maskTransitionName=""
          style={{ top: '3.75rem', margin: '0 0.625rem 0 auto' }}
        />
      ) : null}
      {settingDialogVisible && (
        <SettingDialog
          onCancel={handleCloseSettingDialog}
          isEditPassword={isEditPassword}
        />
      )}
    </>
  ));
};

export default ProfileMyModal;
