import React, { useContext, useMemo } from 'react';
import { Observer } from 'mobx-react';
import { useCoreStores, Tooltip } from 'teespace-core';
import { MailSideView } from 'teespace-mail-app';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { Side as WorksSide } from 'wapl-works-app';
import FriendLnb from '../friends/FriendsLNB';
import RoomList from '../Rooms/RoomList';
import {
  Wrapper,
  CustomTabs,
  UnreadCount,
  TabWrapper,
  TabItemText,
} from './LeftSideStyle';
import { useStores } from '../../stores';
import LeftSideGroup from './LeftSideGroup';
import { i18n } from '../../i18n';

const { TabPane } = CustomTabs;

const LeftSide = () => {
  const { t } = useTranslation();
  const { uiStore } = useStores();
  const { authStore } = useCoreStores();
  const { isFirstLogin } = authStore.sessionInfo;

  const scrollTop = key => {
    const idMap = {
      f: 'lnb__friend-container',
      s: 'lnb__room-container',
    };

    const container = document.getElementById(idMap[key]);
    if (container) container.scrollTo(0, 0);
  };

  const handleSelectTab = key => {
    if (key !== 'f') {
      const friendContainer = document.getElementById('lnb__friend-container');
      if (friendContainer) friendContainer.scrollTo(0, 0);
    }

    uiStore.tabType = key;
    scrollTop(key);
  };

  const themeContext = useContext(ThemeContext);

  const FriendTabPane = useMemo(() => (
    <TabPane
      key="f"
      tab={
        <Tooltip
          title={t('CM_USER_LIST')}
          placement="bottom"
          color={themeContext.CoreLight}
          clickDisappear
        >
          <TabWrapper>
            <Observer>
              {() => (
                <>
                  <TabItemText>{t('CM_USERS')}</TabItemText>
                  <UnreadCount isVisible={uiStore.newFriendCount > 0}>
                    {uiStore.newFriendCount}
                  </UnreadCount>
                </>
              )}
            </Observer>
          </TabWrapper>
        </Tooltip>
      }
    >
      <FriendLnb />
    </TabPane>
  ));

  const RoomTabPane = useMemo(() => (
    <TabPane
      key="s"
      tab={
        <Tooltip
          title={t('CM_COMMUNICATION_BAR_02')}
          placement="bottom"
          color={themeContext.CoreLight}
          clickDisappear
        >
          <TabWrapper>
            <Observer>
              {() => (
                <>
                  <TabItemText>{t('CM_ROOM')}</TabItemText>
                  <UnreadCount isVisible={uiStore.totalUnreadCount > 0}>
                    {uiStore.totalUnreadCount > 99
                      ? '99+'
                      : uiStore.totalUnreadCount}
                  </UnreadCount>
                </>
              )}
            </Observer>
          </TabWrapper>
        </Tooltip>
      }
    >
      <RoomList />
    </TabPane>
  ));

  return (
    <Wrapper>
      <LeftSideGroup created={isFirstLogin} />
      <Observer>
        {() => {
          if (uiStore.resourceType === 'mail')
            return <MailSideView language={i18n.language} />;

          if (uiStore.resourceType === 'works') return <WorksSide />;

          return (
            <CustomTabs
              activeKey={uiStore.tabType}
              onTabClick={handleSelectTab}
              animated={false}
            >
              {FriendTabPane}
              {RoomTabPane}
            </CustomTabs>
          );
        }}
      </Observer>
    </Wrapper>
  );
};

export default LeftSide;
