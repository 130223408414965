import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import { useCoreStores, Tooltip, logEvent } from 'teespace-core';
import { ThemeContext } from 'styled-components';
import { NotiStore } from 'wapl-works-app';
import { MailStore, initMailRoute } from 'teespace-mail-app';
import { useStores } from '../../stores';
import * as useCommand from '../../hook/Command';
import MovePage from '../../utils/MovePage';
import { isStandAlone } from '../../utils/GeneralUtil';
import {
  Wrapper,
  ItemWrapper,
  HomeItemWrapper,
  HorizontalBar,
  UnreadCount,
} from './AppSideStyle';
import {
  WaplIcon,
  AppTalkActiveIcon,
  AppTalkIcon,
  AppMeetingActiveIcon,
  AppMeetingIcon,
  AppWorksActiveIcon,
  AppWorksIcon,
  AppMailActiveIcon,
  AppMailIcon,
} from '../Icons';

const ICON_SIZE = 1.5;

const CountBadge = ({ count }) => {
  if (!count || count <= 0) return null;

  const unreadCount = count > 99 ? '99+' : count;
  return <UnreadCount>{unreadCount}</UnreadCount>;
};

const equalAppProps = (prev, next) => {
  return prev.isActive === next.isActive && prev.count === next.count;
};

const App = React.memo(
  ({ title, isActive, activeIcon, defaultIcon, count, onClick }) => {
    const { t } = useTranslation();
    const themeContext = useContext(ThemeContext);
    const icon = React.cloneElement(isActive ? activeIcon : defaultIcon, {
      color: themeContext.BasicDark,
    });
    const handleMove = () => {
      if (isActive || !onClick) return;
      onClick();
    };

    return (
      <Tooltip
        title={t(title)}
        placement="right"
        color={themeContext.CoreLight}
        zIndex={5}
        clickDisappear
      >
        <ItemWrapper onClick={handleMove}>
          {icon}
          <CountBadge count={count} />
        </ItemWrapper>
      </Tooltip>
    );
  },
  equalAppProps,
);

const AppSide = () => {
  const { t } = useTranslation();
  const { roomStore } = useCoreStores();
  const { uiStore } = useStores();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const handleHome = () => MovePage('spaces');

  const handleOpenMail = async () => {
    const mailRoute = await initMailRoute();
    history.push(`/mail/${mailRoute}`);
    logEvent('gnb', 'clickTeeMailBtn');
  };

  useCommand.OpenApp('mail', handleOpenMail);

  return (
    <Wrapper>
      {isStandAlone() ? (
        <HomeItemWrapper style={{ cursor: 'auto' }}>
          <WaplIcon width={ICON_SIZE} height={ICON_SIZE} />
        </HomeItemWrapper>
      ) : (
        <Tooltip
          title={t('CM_APP_HOME')}
          placement="right"
          color={themeContext.CoreLight}
        >
          <HomeItemWrapper onClick={handleHome}>
            <WaplIcon width={ICON_SIZE} height={ICON_SIZE} />
          </HomeItemWrapper>
        </Tooltip>
      )}
      <HorizontalBar width={ICON_SIZE} />
      <Observer>
        {() => {
          const appList = [
            {
              title: 'CM_APP_TALK',
              isActive:
                uiStore.resourceType === 'f' || uiStore.resourceType === 's',
              activeIcon: <AppTalkActiveIcon />,
              defaultIcon: <AppTalkIcon />,
              count: uiStore.totalUnreadCount,
              onClick: () => history.push(`/s/${roomStore.myRoom?.id}/talk`),
            },
            // {
            //   title: 'CM_APP_MEETING',
            //   isActive: false,
            //   activeIcon: <AppMeetingActiveIcon />,
            //   defaultIcon: <AppMeetingIcon />,
            // },
            {
              title: 'CM_APP_WORKS',
              isActive: uiStore.resourceType === 'works',
              activeIcon: <AppWorksActiveIcon />,
              defaultIcon: <AppWorksIcon />,
              count: NotiStore.unreadTotalCount,
              onClick: () => history.push(`/works/task/kanban`),
            },
            {
              title: 'CM_APP_MAIL',
              isActive: uiStore.resourceType === 'mail',
              activeIcon: <AppMailActiveIcon />,
              defaultIcon: <AppMailIcon />,
              count: MailStore.unreadTotalCount,
              onClick: handleOpenMail,
            },
          ];

          return appList.map(elem => (
            <App
              key={elem.title}
              title={elem.title}
              isActive={elem.isActive}
              activeIcon={elem.activeIcon}
              defaultIcon={elem.defaultIcon}
              count={elem.count}
              onClick={elem.onClick}
            />
          ));
        }}
      </Observer>
    </Wrapper>
  );
};

export default React.memo(AppSide);
