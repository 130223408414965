import { UserStore, SpaceStore } from 'teespace-core';
import { fallbackLanguage } from '../i18n';

export const isB2B = () => UserStore.myProfile.type === 'USR0001';

export const isSpaceAdmin = () => {
  return (
    UserStore.myProfile.grade === 'admin' ||
    UserStore.myProfile.grade === 'superadmin'
  );
};

export const isBasicPlan = () => SpaceStore.currentSpace?.plan === 'BASIC';

export const getLanguage = () => {
  const { language } = UserStore.myDomainSetting;
  if (!language) return fallbackLanguage;

  const match = language.match(/en|ko/g);
  const isValidLanguage = !!match;
  if (isValidLanguage) return match?.[0];

  return fallbackLanguage;
};

export const remToPixel = rem =>
  parseFloat(getComputedStyle(document.documentElement).fontSize, 10) * rem;

export const isDarkMode = () =>
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

// For LNB Modal
export const getLeftDistance = adjust => {
  const MODAL_LEFT = 19.935;
  let distance = MODAL_LEFT;

  if (adjust) distance += adjust;

  return `${distance}rem`;
};

export const isStandAlone = () => {
  return window.env.IS_STANDALONE === 'yes';
};
