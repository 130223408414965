import React from 'react';
import { Observer } from 'mobx-react';
import Header from '../header/Header';
import Content from './Content';
import { Wrapper } from './MainSideStyle';
import { useStores } from '../../stores';

const MainSide = () => {
  const { uiStore } = useStores();

  return (
    <Wrapper>
      <Observer>{() => <Header appType={uiStore.resourceType} />}</Observer>
      <Content />
    </Wrapper>
  );
};

export default MainSide;
