import React, { useState } from 'react';
import { Button } from 'antd';
import { Input, useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../common/ErrorIcon';
import { updateMyProfile } from '../../../utils/ProfileUtil';
import { handleCheckValidBirthDate } from '../../../libs/Regex';
import {
  InnerItem,
  Name,
  Data,
  TextArea,
  ButtonArea,
  InputArea,
} from '../../../styles/usersettings/SettingDialogStyle';

const SettingDialogBirthDate = () => {
  const { t } = useTranslation();
  const { userStore } = useCoreStores();
  const { myProfile } = userStore;
  const [birthDate, setBirthDate] = useState(myProfile.birthDate);
  const [isBirthDateEdit, setIsBirthDateEdit] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);

  const handleChangeBirthDate = async () => {
    if (errorVisible) return;
    if (isProceeding) return;
    try {
      setIsProceeding(true);
      await updateMyProfile({ birthDate });
      setIsProceeding(false);
      setIsBirthDateEdit(false);
    } catch (e) {
      console.log(`changeBirthDay Error is ${e}`);
      setIsProceeding(false);
    }
  };
  const handleCancelChange = () => {
    setIsBirthDateEdit(false);
    setBirthDate(myProfile.birthDate);
    if (errorVisible) setErrorVisible(false);
  };

  return (
    <InnerItem>
      <Name>{t('CM_DATE_BIRTH')}</Name>
      <Data>
        <TextArea>
          {isBirthDateEdit ? (
            <InputArea>
              <Input
                type="number"
                maxLength={8}
                value={birthDate}
                placeholder="YYYYMMDD"
                onChange={e => {
                  const { value } = e.target;
                  const valid =
                    value === '' || handleCheckValidBirthDate(value);

                  if (!valid) setErrorVisible(true);
                  else if (errorVisible && valid) setErrorVisible(false);
                  setBirthDate(value);
                }}
              />
              <ErrorIcon
                visible={errorVisible}
                tooltipText="ADMIN_SUBS_MANAGECORP_CORPINFO_SETTING_BIRTH"
              />
            </InputArea>
          ) : (
            <p>{myProfile.birthDate || '-'}</p>
          )}
        </TextArea>
        <ButtonArea>
          {isBirthDateEdit ? (
            <>
              <Button
                size="small"
                type="solid"
                disabled={myProfile.birthDate === birthDate}
                onClick={handleChangeBirthDate}
              >
                {t('CM_SAVE')}
              </Button>
              <Button size="small" type="outlined" onClick={handleCancelChange}>
                {t('CM_CANCEL')}
              </Button>
            </>
          ) : (
            <Button
              size="small"
              type="outlined"
              onClick={() => setIsBirthDateEdit(true)}
            >
              {t('CM_CHANGE')}
            </Button>
          )}
        </ButtonArea>
      </Data>
    </InnerItem>
  );
};

export default React.memo(SettingDialogBirthDate);
