import React, { useCallback, useContext } from 'react';
import { useCoreStores } from 'teespace-core';
import { Button } from 'antd';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isSpaceAdmin } from '../../utils/GeneralUtil';
import { BubbleTailIcon } from '../Icons';

const Wrap = styled.div`
  position: absolute;
  top: ${props => (props.isSpaceAdmin ? '3.75rem' : '0.625rem')};
  left: 19.875rem;
  width: 23.75rem;
  padding: 1.91rem 1rem 0.875rem;
  border-radius: 0.625rem;
  background: ${props => props.theme.StateNormal};
  text-align: center;
  z-index: 205;
`;

const BubbleTail = styled.span`
  position: absolute;
  top: 0.5rem;
  left: -0.563rem;
`;

const Title = styled.strong`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.81rem;
  color: ${props => props.theme.TextMain};
`;

const Description = styled.p`
  margin-bottom: 1.25rem;
  font-size: 0.75rem;
  white-space: pre-line;
  line-height: 1.13rem;
  color: ${props => props.theme.TextSub};
`;

const SubDescription = styled(Description)`
  font-size: 0.69rem;
  opacity: 0.7;
`;

const ButtonContainer = styled.div`
  .ant-btn-solid {
    width: 9.25rem;
    &:only-of-type {
      width: 16.06rem;
    }
    & + .ant-btn-solid {
      margin-left: 0.5rem;
    }
  }
  .ant-btn-link {
    margin-top: 0.625rem;
    font-size: 0.813rem;
    color: ${props => props.theme.GreenDeep};
    &:active,
    &:hover:active:focus {
      color: ${props => props.theme.GreenDeep};
    }
  }
`;

function GroupModal({
  userName,
  onInvite = () => {},
  onRoomCreate = () => {},
  onAddFriend = () => {},
  onClose = () => {},
}) {
  const { t } = useTranslation();
  const { userStore, spaceStore } = useCoreStores();
  const { isGuest } = userStore.myProfile;
  const themeContext = useContext(ThemeContext);

  const title = isSpaceAdmin()
    ? t('CM_SPACE_CREATE_COMPLETE_01')
    : t('CM_INVITE_PEOPLE_MAIL_LINK_09');

  const handleAddMember = useCallback(() => {
    onClose();
    onInvite();
  }, [onClose, onInvite]);

  const handleAdminPage = useCallback(() => {
    window.open(`${window.location.origin}/admin`);
  }, []);

  const handleCreateRoom = useCallback(() => {
    onClose();
    onRoomCreate();
  }, [onClose, onRoomCreate]);

  const handleAddFriend = useCallback(() => {
    onClose();
    onAddFriend();
  }, [onClose, onAddFriend]);

  const getButtons = () => {
    if (isGuest) {
      return null;
    }
    if (isSpaceAdmin()) {
      return (
        <>
          <Button type="solid" onClick={handleAddMember}>
            {t('CM_USER_INVITE')}
          </Button>
          <Button
            type="solid"
            className="color-green"
            onClick={handleAdminPage}
          >
            {t('CM_ADMIN_PAGE')}
          </Button>
          <Button type="link" onClick={onClose}>
            {t('CM_SKIP')}
          </Button>
        </>
      );
    }
    return (
      <>
        <Button type="solid" onClick={handleAddFriend}>
          {t('CM_ADD_PHOTO_FRIENDS')}
        </Button>
        <Button type="solid" className="color-green" onClick={handleCreateRoom}>
          {t('CM_CREATE_ROOM')}
        </Button>
        <Button type="link" onClick={onClose}>
          {t('CM_SKIP')}
        </Button>
      </>
    );
  };

  const getDescription = () => {
    if (isSpaceAdmin()) {
      return (
        <Description>
          {t('CM_SPACE_CREATE_COMPLETE_02', {
            name1: userName,
            name2: spaceStore.currentSpace?.name,
          })}
        </Description>
      );
    }
    if (isGuest) {
      return (
        <>
          <Description>
            {t('CM_GUEST_LAYOUT_01', {
              name1: userName,
              name2: spaceStore.currentSpace?.name,
            })}
          </Description>
          <SubDescription>{t('CM_GUEST_LAYOUT_02')}</SubDescription>
        </>
      );
    }
    return (
      <Description>
        {t('CM_INVITE_PEOPLE_MAIL_LINK_10', {
          name1: userName,
          name2: spaceStore.currentSpace?.name,
        })}
      </Description>
    );
  };

  return (
    <Wrap isSpaceAdmin={isSpaceAdmin()}>
      <BubbleTail>
        <BubbleTailIcon color={themeContext.StateNormal} />
      </BubbleTail>
      <Title>{title}</Title>
      {getDescription()}
      <ButtonContainer>{getButtons()}</ButtonContainer>
    </Wrap>
  );
}

export default GroupModal;
