import React from 'react';
import { useCoreStores, Button } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores';
import {
  ContentItem,
  ItemTitle,
  ItemInfo,
  ButtonBox,
} from '../../../styles/usersettings/ContentAccountStyle';

const MyInvitationCode = () => {
  const { t } = useTranslation();
  const {
    userStore: {
      myDomainSetting: { inviteCode },
    },
  } = useCoreStores();
  const { uiStore } = useStores();

  const handleCopy = () => {
    if (!inviteCode) return;

    navigator.clipboard.writeText(inviteCode).then(() => {
      uiStore.openToast({
        text: t('CM_EDIT_MYPAGE_10'),
      });
    });
  };

  return (
    <ContentItem>
      <ItemTitle>{t('CM_SETTING_INVITE_CODE_03')}</ItemTitle>
      <ItemInfo>
        {inviteCode || '-'}
        <ButtonBox>
          <Button size="small" onClick={handleCopy}>
            {t('TEMP_COPY')}
          </Button>
        </ButtonBox>
      </ItemInfo>
    </ContentItem>
  );
};

export default React.memo(MyInvitationCode);
