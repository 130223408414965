import React, { useState } from 'react';
import { Button } from 'antd';
import { Input, useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import ErrorIcon from '../../common/ErrorIcon';
import {
  getMobileNumber,
  updateMyProfile,
  handlePhoneNumber,
} from '../../../utils/ProfileUtil';
import {
  InnerItem,
  Name,
  Data,
  TextArea,
  ButtonArea,
  InputArea,
} from '../../../styles/usersettings/SettingDialogStyle';

const SettingDialogPhone = () => {
  const { t } = useTranslation();
  const { userStore } = useCoreStores();
  const { myProfile } = userStore;
  const [phone, setPhone] = useState(myProfile.phone);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);

  const handleChangePhone = async () => {
    if (errorVisible) return;
    if (isProceeding) return;
    try {
      setIsProceeding(true);
      await updateMyProfile({ phone });
      setIsProceeding(false);
      setIsPhoneEdit(false);
    } catch (e) {
      console.log(`changeCellPhone Error is ${e}`);
      setIsProceeding(false);
    }
  };
  const handleCancelChange = () => {
    setIsPhoneEdit(false);
    setPhone(myProfile.phone);
    if (errorVisible) setErrorVisible(false);
  };

  return (
    <InnerItem>
      <Name>{t('CM_MOBILE_NUMBER')}</Name>
      <Data>
        <TextArea>
          {isPhoneEdit ? (
            <InputArea>
              <Input
                value={phone}
                onChange={e => {
                  const { value } = e.target;
                  handlePhoneNumber(
                    value,
                    errorVisible,
                    () => setErrorVisible(true),
                    () => setErrorVisible(false),
                    num => setPhone(num),
                  );
                }}
              />
              <ErrorIcon
                visible={errorVisible}
                tooltipText="ADMIN_MANAGECORP_MANAGEORG_MEM_REGISTRATION_03"
              />
            </InputArea>
          ) : (
            <Observer>
              {() => <p>{getMobileNumber(userStore.myProfile)}</p>}
            </Observer>
          )}
        </TextArea>
        <ButtonArea>
          {isPhoneEdit ? (
            <>
              <Button
                size="small"
                type="solid"
                disabled={myProfile.phone === phone}
                onClick={handleChangePhone}
              >
                {t('CM_SAVE')}
              </Button>
              <Button size="small" type="outlined" onClick={handleCancelChange}>
                {t('CM_CANCEL')}
              </Button>
            </>
          ) : (
            <Button
              size="small"
              type="outlined"
              onClick={() => setIsPhoneEdit(true)}
            >
              {t('CM_CHANGE')}
            </Button>
          )}
        </ButtonArea>
      </Data>
    </InnerItem>
  );
};

export default React.memo(SettingDialogPhone);
