import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileInfoModal } from 'teespace-core';
import FriendsLNBList from './FriendsLNBList';
import { getLeftDistance } from '../../utils/GeneralUtil';
import { openMeeting } from '../../utils/RoomUtil';

/**
 * @param {string} searchKeyword - 프렌즈 검색 키워드
 */

const FriendsLNBContent = ({ searchKeyword }) => {
  const history = useHistory();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const handleOpenInfoModal = useCallback(value => {
    setSelectedId(value);
    setIsInfoModalVisible(true);
  }, []);
  const handleCloseInfoModal = useCallback(() => {
    setIsInfoModalVisible(false);
  }, []);

  return (
    <>
      <FriendsLNBList
        searchKeyword={searchKeyword}
        handleOpenInfoModal={handleOpenInfoModal}
      />
      {isInfoModalVisible && (
        <ProfileInfoModal
          userId={selectedId}
          visible
          onClickMeeting={roomInfo => {
            openMeeting(roomInfo);
            history.push(`/s/${roomInfo.id}/talk`);
          }}
          onClose={handleCloseInfoModal}
          position={{ left: getLeftDistance() }}
        />
      )}
    </>
  );
};

export default React.memo(FriendsLNBContent);
