import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 0.625rem 0.75rem;
`;

export const ThumbNail = styled.div`
  display: flex;
  width: ${props => props.size};
  height: ${props => props.size};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${props =>
    props.border &&
    css`
      border: solid 1px ${props.theme.CoreVivid};
    `};
  border-radius: ${props => props.borderRadius};
  background-color: ${props =>
    props.bright ? props.theme.StateBright : props.theme.SubStateBright};
  font-size: ${props => props.fontSize};
  font-weight: 500;
  line-height: 1;
  color: ${props => props.theme.TextSub4};
  overflow: hidden;
`;

export const ThumbPhoto = styled.img`
  width: 100%;
  height: 100%;
`;

export const GroupSelector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.313rem;
  line-height: 1;
  border-radius: 0.5rem;
  background-color: ${props => props.theme.CoreNormal};
  cursor: pointer;
`;

export const SelectedTitle = styled.h2`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin-left: 0.625rem;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.25rem;
  color: ${props => props.theme.StateNormal};
`;

export const GroupDimmedWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
`;

export const GroupDropdown = styled.div`
  overflow: hidden;
  width: 14.875rem;
  margin: 3.063rem 0 0 3.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 0.25rem 0 rgba(0, 0, 0, 0.2);
  border: solid 1px ${props => props.theme.LineOut};
  background-color: ${props => props.theme.StateNormal};
`;

export const GroupArea = styled.div`
  display: flex;
  align-items: center;
  padding: 0.938rem;
  background-color: ${props => props.theme.StateDark};

  .ant-checkbox .ant-checkbox-inner {
    background-color: ${props => props.theme.StateDark};
  }
`;

export const GroupInfo = styled.div`
  overflow: hidden;
  margin: 0 0.2rem 0 0.625rem;
  flex: 1;
`;

export const InfoTitle = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  color: ${props => props.theme.TextMain};
`;

export const InfoDomain = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  margin-top: 0.063rem;
  font-size: 0.625rem;
  line-height: 0.938rem;
  color: ${props => props.theme.TextSub};
`;

export const OptionArea = styled.ul`
  position: relative;
  padding: 0.375rem 0 0.438rem;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 0.063rem;
    background-color: ${props => props.theme.LineSub};
  }
`;

export const OptionItem = styled.li`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.375rem 1.313rem;
  font-size: 0.813rem;
  font-weight: 500;
  line-height: 1.063rem;
  color: ${props => props.theme.CoreNormal};
  cursor: pointer;
`;

export const GroupControlArea = styled.div`
  overflow: auto;
  max-height: 19.5rem;
`;

export const ControlBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.938rem;
  cursor: pointer;
`;

export const ControlText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 1rem;
  flex: 1;
  font-size: 0.75rem;
  font-weight: 500;
  color: ${props => props.theme.TextMain};
`;

export const ControlAddText = styled(ControlText)`
  color: ${props => props.theme.TextSub};
`;

export const NewBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  padding: 0 0.25rem;
  min-width: 0.875rem;
  height: 0.875rem;
  line-height: 1;
  font-size: 0.625rem;
  color: #fff;
  font-weight: 500;
  border-radius: 0.625rem;
  background-color: #dc4547;
`;
