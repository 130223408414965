import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useCoreStores } from 'teespace-core';
import Cookies from 'js-cookie';

function MobileLoginRoute({ component: Component, ...rest }) {
  const { authStore } = useCoreStores();
  const history = useHistory();

  const url = window.location.origin; //  http://xxx.dev.teespace.net
  const conURL = url.split(`//`)[1]; // xxx.dev.teespace.net
  const mainURL = conURL.slice(conURL.indexOf('.') + 1, conURL.length); // dev.teespace.net
  let domainName;
  let loginInfo;

  return (
    <Route
      {...rest}
      render={props => {
        (async () => {
          if (process.env.REACT_APP_ENV === 'local') {
            // [domainName] = new URL(
            //   process.env.REACT_APP_DOMAIN_URL,
            // ).hostname.split('.');
            // loginInfo = {
            //   id: keycloak.tokenParsed.preferred_username,
            //   deviceType: 'PC',
            //   domainUrl: domainName,
            //   isLocal: 'local',
            // };
          } else {
            [domainName] = url.split(`//`)[1].split(`.`);
            loginInfo = {
              deviceType: 'PC',
              domainUrl: '',
            };
          }

          try {
            if (authStore.user?.loginId) {
              await authStore.logout().then(() => {
                Cookies.remove('ACCESS_TOKEN');
                Cookies.remove('DEVICE_TYPE');
              });
            }

            const res = await authStore.login(loginInfo);

            if (res) {
              Cookies.set(
                'DEVICE_TYPE',
                'PC',
                process.env.REACT_APP_ENV === 'local'
                  ? {}
                  : {
                      domain: `.${window.location.host}`,
                    },
              );
            }

            // NOTE. 이전 경로가 존재하면 해당 경로로 이동
            const stateFrom = props.location.state?.from;
            if (stateFrom) {
              history.push(
                `${stateFrom.pathname}${props.location.state?.from.search}`,
              );
            } else {
              history.push(`/f/${authStore.user.id}/profile`);
            }
          } catch (e) {
            // window.location.href = `${window.location.protocol}//${mainURL}/domain/${domainName}`;
            console.error(e);
          }
        })();
        return null;
      }}
    />
  );
}

export default MobileLoginRoute;
