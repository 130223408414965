/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useCoreStores } from 'teespace-core';
import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import { useStores } from '../../stores';
import ContentTitle from './ContentTitle';
import Search from '../common/Search';

const ContentManageBlockedUser = () => {
  const { userStore } = useCoreStores();
  const { uiStore } = useStores();
  const [totalUserList, setTotalUserList] = useState([]);
  const [blockedUserList, setBlockedUserList] = useState([]);
  const [selectedIdList, setSelectedIdList] = useState([]);

  const init = async () => {
    const res = await userStore.getBlockedUserList();
    setBlockedUserList(res);
    setTotalUserList(res);
    setSelectedIdList([]);
  };

  useEffect(() => {
    (async () => {
      init();
    })();
  }, []);

  const handleSingleCheck = (checked, userId) => {
    if (checked) {
      setSelectedIdList(prev => [...prev, userId]);
    } else {
      setSelectedIdList(selectedIdList.filter(id => id !== userId));
    }
  };

  const handleAllCheck = checked => {
    if (checked) {
      const idArray = blockedUserList.map(user => user.id);
      setSelectedIdList(idArray);
    } else {
      setSelectedIdList([]);
    }
  };

  const handleKeywordChange = keyword => {
    const filteredUserList = totalUserList.filter(user =>
      user.nick.includes(keyword),
    );
    setBlockedUserList(filteredUserList);
  };

  const handleKeywordClear = () => {
    setBlockedUserList(totalUserList);
  };

  const handleUnblockUser = async () => {
    try {
      const userIdList = selectedIdList;
      await userStore.unblockUser({ userIdList });
      uiStore.openToast({
        text: '차단이 해제되었습니다.',
      });
    } catch (e) {
      console.log(e);
    } finally {
      init();
    }
  };

  const handleSingleUnblockUser = async userId => {
    try {
      const userIdList = [userId];
      await userStore.unblockUser({ userIdList });
      uiStore.openToast({
        text: '차단이 해제되었습니다.',
      });
    } catch (e) {
      console.log(e);
    } finally {
      init();
    }
  };

  return (
    <>
      <ContentTitle
        title="차단 구성원 관리"
        subTitle="차단한 구성원 목록입니다. 차단을 해제할 수 있습니다."
      />
      <ContentHeader>
        <HeaderLeft>
          <HeaderText>{`차단한 구성원 ${totalUserList.length}명`}</HeaderText>
          <HeaderButton
            disabled={!selectedIdList.length}
            onClick={handleUnblockUser}
            type="solid"
            shape="default"
          >
            차단 해제
          </HeaderButton>
        </HeaderLeft>
        <SearchWrapper>
          <Search
            placeholder="구성원 검색"
            onChange={handleKeywordChange}
            onClear={handleKeywordClear}
            searchIconColor={{ active: '#48423B', default: '#48423B' }}
            type="border"
            className="block-search-wrapper"
          />
        </SearchWrapper>
      </ContentHeader>
      <ContentListHeader
        selectedIdList={selectedIdList}
        blockedUserList={blockedUserList}
        handleAllCheck={handleAllCheck}
      />
      <ContentList
        blockedUserList={blockedUserList}
        selectedIdList={selectedIdList}
        handleSingleCheck={handleSingleCheck}
        handleSingleUnblockUser={handleSingleUnblockUser}
      />
    </>
  );
};
const ContentListHeader = ({
  selectedIdList,
  handleAllCheck,
  blockedUserList,
}) => {
  const handleChange = e => {
    handleAllCheck(e.target.checked);
  };
  const checked =
    selectedIdList.length !== 0 &&
    selectedIdList.length === blockedUserList.length;
  return (
    <HorizontalWrapper>
      <CheckboxArea>
        <Checkbox
          className="check-round"
          checked={checked}
          onChange={handleChange}
        />
      </CheckboxArea>
      <NickNameArea>별명</NickNameArea>
      <BlockedDateArea>차단 일시</BlockedDateArea>
      <UnblockArea>관리</UnblockArea>
    </HorizontalWrapper>
  );
};

const ContentList = ({
  blockedUserList,
  selectedIdList,
  handleSingleCheck,
  handleSingleUnblockUser,
}) => {
  return (
    <Scroll>
      {blockedUserList &&
        blockedUserList.map(blockedUser => (
          <ContentListItem
            key={blockedUser.id}
            userData={blockedUser}
            selectedIdList={selectedIdList}
            handleSingleCheck={handleSingleCheck}
            handleSingleUnblockUser={handleSingleUnblockUser}
          />
        ))}
    </Scroll>
  );
};

const ContentListItem = ({
  userData,
  selectedIdList,
  handleSingleCheck,
  handleSingleUnblockUser,
}) => {
  const blockedDate = userData.blockedDate.split(' ')[0];
  const checked = selectedIdList.includes(userData.id);
  const handleChange = e => {
    handleSingleCheck(e.target.checked, userData.id);
  };
  const handleUnblock = async () => {
    handleSingleUnblockUser(userData.id);
  };
  return (
    <HorizontalWrapper className="blocked-user-list-item">
      <CheckboxArea>
        <Checkbox
          className="check-round"
          checked={checked}
          onChange={handleChange}
        />
      </CheckboxArea>
      <NickNameArea>{userData.nick}</NickNameArea>
      <BlockedDateArea>{blockedDate}</BlockedDateArea>
      <UnblockArea>
        <ItemButton type="solid" shape="default" onClick={handleUnblock}>
          차단 해제
        </ItemButton>
      </UnblockArea>
    </HorizontalWrapper>
  );
};

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 1.25rem 0.625rem 1.25rem;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
  justify-content: center;
  font-size: 0.813rem;
  font-weight: 500;
`;
const HeaderButton = styled(Button)`
  display: flex;
  width: 4.5rem;
  height: 1.875rem;
  margin-left: 1.25rem;
  padding: 0.375rem 0.771rem 0.375rem 0.75rem;
  border-radius: 4px;
  background-color: ${props => props.theme.Green};
  &:hover {
    background-color: ${props => props.theme.GreenBright};
  }
  color: #fff;
  cursor: pointer;
`;
const ItemButton = styled(Button)`
  background-color: #fff;
  border: 1px solid ${props => props.theme.InputActive};
  width: 4.5rem;
  height: 1.875rem;
  color: #666666;
`;
const SearchWrapper = styled.div`
  display: flex;
  width: 13.313rem;
  height: 1.875rem;
  border-radius: 6px;
  background-color: ${props => props.theme.Beige};
  .block-search-wrapper {
    border: 1px solid ${props => props.theme.Beige} !important;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;
  height: 3.188rem;
  margin: 0 1.25rem;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.BasicDark};
  &.blocked-user-list-item {
    border-bottom: 1px solid #e3e7eb;
  }
`;
const CheckboxArea = styled.div`
  display: flex;
  flex: 3rem 0 0;
  justify-content: center;
`;
const NickNameArea = styled.div`
  display: block;
  flex: 7.25rem 0 0;
  padding-left: 0.75rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const BlockedDateArea = styled.div`
  display: flex;
  flex: 1;
  padding-left: 0.75rem;
`;
const UnblockArea = styled.div`
  display: flex;
  flex: 6.5rem 0 0;
  padding-left: 0.75rem;
`;
const Scroll = styled.div`
  flex: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;
export default ContentManageBlockedUser;
