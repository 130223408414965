import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { Error2Icon } from '../Icons';

const ErrorIcon = ({ visible, tooltipText, zIndex }) => {
  const { t } = useTranslation();

  if (!visible) return null;

  const props = zIndex
    ? { title: t(tooltipText), zIndex }
    : { title: t(tooltipText) };

  return (
    <Tooltip placement="top" color="#FB3A3A" visible {...props}>
      <div style={{ marginLeft: '0.5rem' }}>
        <Error2Icon />
      </div>
    </Tooltip>
  );
};

export default ErrorIcon;
