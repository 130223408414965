import styled, { css } from 'styled-components';

export const Loader = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    user-select: none;

    & img {
      width: 5rem;
      height: auto;
    }
}`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  // width: 100%;
  flex: 1;
  height: 100%;
  & * {
    user-select: none;
  }

  ${props =>
    props.type !== 'works' &&
    css`
      min-width: 65.88rem;
    `}

  ${props =>
    props.type === 'works' &&
    css`
      overflow: hidden;
    `}
`;
