import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Talk } from 'teespace-talk-app';
import { App as MeetingApp } from 'teespace-meeting-app';
import { EventBus, useCoreStores, ProfileInfoModal } from 'teespace-core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import LoadingImg from '../assets/WAPL_Loading.gif';
import RoomInquiryModal from '../components/Rooms/RoomInquiryModal';
import Photos from '../components/Photos';
import { SearchIcon } from '../components/Icons';
import WindowManager from '../components/common/WindowManager';
import { useStores } from '../stores';
import { handleProfileMenuClick } from '../utils/ProfileUtil';
import { isDarkMode } from '../utils/GeneralUtil';
import { getName, openMeeting } from '../utils/RoomUtil';

const NewWindowPage = () => {
  const { resourceId: roomId, mainApp } = useParams();
  const {
    roomStore,
    userStore,
    spaceStore,
    friendStore,
    themeStore,
    componentStore,
  } = useCoreStores();
  const { i18n } = useTranslation();
  const { uiStore } = useStores();
  const history = useHistory();
  const myUserId = userStore.myProfile.id;

  const [isLoaded, setIsLoaded] = useState(false);

  const getChannelId = type => {
    return roomStore
      .getRoomMap()
      .get(roomId)
      ?.channelList?.find(channel => channel.type === type)?.id;
  };

  const init = async () => {
    try {
      Promise.all([
        spaceStore.fetchSpaces({
          userId: myUserId,
          isLocal: process.env.REACT_APP_ENV === 'local',
        }),
        friendStore.fetchFriends(),
        roomStore.fetchRoom({ roomId }),
        userStore.getMyDomainSetting(),
      ]).then(async () => {
        if (!userStore.myDomainSetting.language) {
          await userStore.updateMyDomainSetting({
            language: i18n.language,
          });
        } else i18n.changeLanguage(userStore.myDomainSetting.language);

        const platformTheme = userStore.myDomainSetting.theme;
        if (platformTheme && platformTheme !== 'system')
          themeStore.setTheme(platformTheme);
        else if (isDarkMode()) themeStore.setTheme('dark');
        setIsLoaded(true);
      });
    } catch (err) {
      console.error('Mini Talk Error : ', err);
    }
  };

  useEffect(() => {
    init();

    const openMeetingHandler = EventBus.on(
      'onMeetingOpen',
      ({ roomId: targetRoomId }) => {
        const roomInfo = roomStore.getRoom(targetRoomId);
        openMeeting(roomInfo);
      },
    );
    const directMessageHandler = EventBus.on(
      'Platform:directMessage',
      ({ userId }) => {
        const moveTalk = targetRoomId =>
          history.push(`/s/${targetRoomId}/talk`);

        handleProfileMenuClick(
          myUserId,
          userId,
          roomInfo => moveTalk(roomInfo.id),
          roomInfo => moveTalk(roomInfo.id),
        );
      },
    );

    return () => {
      EventBus.off('onMeetingOpen', openMeetingHandler);
      EventBus.off('Platform:directMessage', directMessageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSearch = () => EventBus.dispatch('Talk:OpenSearch');

  const MeetingModal = componentStore.get('Meeting:MeetingSelectModal');

  if (!isLoaded)
    return (
      <Loader>
        <img src={LoadingImg} alt="loader" />
      </Loader>
    );

  switch (mainApp) {
    case 'talk':
      return (
        <Wrapper>
          <WindowManager />

          {/* Meeting Modal */}
          <Observer>
            {() => (
              <MeetingModal
                meetingStart={uiStore.meetingStart}
                roomId={uiStore.meetingRoomId}
                roomName={uiStore.meetingRoomName}
                startCallback={uiStore.meetingStartCallback}
              />
            )}
          </Observer>

          <Header roomId={roomId} onSearch={openSearch} />
          <Content>
            <Talk
              roomId={roomId}
              channelId={getChannelId('CHN0001')}
              isMini
              option={{ isMini: true }}
            />
          </Content>
        </Wrapper>
      );
    case 'meeting':
      return (
        <>
          <WindowManager />
          <MeetingApp
            language={i18n.language}
            roomId={roomId}
            channelId={getChannelId('CHN0005')}
            layoutState="expand"
          />
        </>
      );
    default:
      return null;
  }
};

export default NewWindowPage;

const Header = ({ roomId, onSearch }) => {
  const { roomStore, userStore } = useCoreStores();
  const [modalVisible, setModalVisible] = useState(false);

  const getRoom = () => {
    return roomStore.getRoom(roomId);
  };

  const handlePhotoClick = () => {
    const info = getRoom();
    if (!info || (info.isDirectMsg && info.userCount === 1)) return;

    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const getModal = () => {
    const info = getRoom();
    if (!info) return null;

    if (info.isMyRoom || info.isDirectMsg) {
      const userIds = info.isMyRoom
        ? userStore.myProfile.id
        : info.memberIdListString
            .split(',')
            .find(userId => userId !== userStore.myProfile.id);

      if (!userIds?.length) return null;

      return (
        <ProfileInfoModal
          userId={userIds}
          visible={modalVisible}
          onClickMeeting={roomInfo => openMeeting(roomInfo)}
          onClose={handleModalClose}
          position={{ top: '3.5rem', left: '0' }}
        />
      );
    }

    return (
      modalVisible && (
        <RoomInquiryModal
          roomId={roomId}
          visible={modalVisible}
          onCancel={handleModalClose}
          width="17.5rem"
          top="3.5rem"
          left="0"
        />
      )
    );
  };

  return (
    <HeaderWrapper>
      {getModal()}

      <Observer>
        {() => {
          const info = getRoom();
          if (!info) return null;

          return (
            <Photos
              isClickable={!info.isBotRoom}
              srcList={roomStore.getRoomPhoto(info.id)}
              onClick={handlePhotoClick}
              className="header__room-photo"
            />
          );
        }}
      </Observer>

      <Observer>
        {() => {
          const info = getRoom();
          if (!info) return null;

          return <span className="header__room-name">{getName(info)}</span>;
        }}
      </Observer>

      <Observer>
        {() => {
          const info = getRoom();
          if (!info || info.isDirectMsg || info.isMyRoom) return null;
          return <span className="header__user-count">{info.userCount}</span>;
        }}
      </Observer>

      <IconWrapper onClick={onSearch}>
        <SearchIcon width={1.38} height={1.38} />
      </IconWrapper>
    </HeaderWrapper>
  );
};

const Wrapper = styled.div`
  min-width: 400px;
  min-height: 600px;
  height: 100%;
  user-select: none;
`;

const HeaderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.25rem 0 0.69rem;
  border-bottom: 1px solid ${props => props.theme.LineMain};
  background-color: ${props => props.theme.StateNormal};

  .header__room-photo {
    flex-shrink: 0;
  }

  .header__room-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 0.63rem;
    font-size: 0.875rem;
    color: ${props => props.theme.TextMain};
  }

  .header__user-count {
    margin: 0 0.31rem;
    font-size: 0.875rem;
    color: ${props => props.theme.TextSub2};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: auto;
  flex-shrink: 0;

  &:hover {
    background-color: ${props => props.theme.StateLight};
  }
`;

const Content = styled.div`
  height: calc(100% - 3.75rem);
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & img {
    width: 4.06rem;
    height: 4.06rem;
  }
`;
