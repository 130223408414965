import React, { useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCoreStores } from 'teespace-core';
import { SELECTED_TAB as TAB } from '../../App.properties';
import {
  ContentLanguage,
  ContentTheme,
  ContentProfile,
  ContentAlarm,
  ContentSpaceSecession,
  SettingSave,
  ContentAccount,
  ContentManageBlockedUser,
} from './index';
import { isB2B, isSpaceAdmin } from '../../utils/GeneralUtil';
import {
  DialogWrap,
  LayoutWrap,
  SiderArea,
  ContentArea,
  StyledMenu,
} from '../../styles/usersettings/SettingDialogStyle';

const SettingDialog = ({ onCancel, isEditPassword }) => {
  const { t } = useTranslation();
  const { userStore, configStore } = useCoreStores();

  // 비밀번호 변경 통해 진입시 계정 화면으로 바로 이동
  const [selectedKey, setSelectedKey] = useState(
    isEditPassword ? TAB.ACCOUNT : TAB.PROFILE,
  );
  const [isSecession, setIsSecession] = useState(false);
  const [inputPassword, setInputPassword] = useState('');

  const handleTabClick = async key => {
    // 이벤트 초대 인원수를 실시간으로 update 하고 싶은데, 현재 서버에서 WWMS 메시지가 오지 않는다고 한다.
    // 우선 계정 TAB을 클릭할 때 서비스를 호출함. 추후 변경 여지 있음
    if (key === TAB.ACCOUNT) await userStore.getMyDomainSetting();

    if (selectedKey === TAB.SECESSION && isSecession) {
      setIsSecession(false);
      setInputPassword('');
    }
    setSelectedKey(key);
  };

  const getSettingContent = targetKey => {
    switch (targetKey) {
      case TAB.LANGUAGE:
        return <ContentLanguage />;
      case TAB.THEME:
        return <ContentTheme />;
      case TAB.PROFILE:
        return <ContentProfile />;
      case TAB.ALARM:
        return <ContentAlarm />;
      case TAB.BLOCKED:
        return <ContentManageBlockedUser />;
      case TAB.SECESSION:
        return (
          <ContentSpaceSecession
            isSecession={isSecession}
            toggleSecession={() => setIsSecession(true)}
            onInputChange={input => setInputPassword(input)}
          />
        );
      case TAB.ACCOUNT:
        return <ContentAccount isEdit={isEditPassword} />;
      default:
        return null;
    }
  };

  return (
    <DialogWrap
      visible
      centered
      onCancel={onCancel}
      width="59.38rem"
      title={t('CM_SETTING')}
      style={{ minWidth: '50rem' }}
      footer={
        isSecession ? (
          <SettingSave
            onCancel={() => setIsSecession(false)}
            inputPassword={inputPassword}
          />
        ) : null
      }
    >
      <LayoutWrap>
        <SiderArea>
          <StyledMenu
            selectedKeys={selectedKey}
            onClick={({ item, key }) => handleTabClick(key)}
          >
            <Menu.ItemGroup key="sub1" title={t('CM_SETTING_06')}>
              <Menu.Item key={TAB.ACCOUNT}>{t('CM_EDIT_MYPAGE_01')}</Menu.Item>
              <Menu.Item key={TAB.LANGUAGE}>
                {t('CM_SETTING_GENERAL_04')}
              </Menu.Item>
              <Menu.Item key={TAB.THEME}>
                {t('CM_SETTING_GENERAL_01')}
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="sub2" title={t('CM_SETTING_08')}>
              <Menu.Item key={TAB.PROFILE}>
                {t('CM_ROOMTITLE_TOOLTIP_04')}
              </Menu.Item>
              <Menu.Item key={TAB.ALARM}>{t('CM_NOTI')}</Menu.Item>
              <Menu.Item key={TAB.BLOCKED}>차단 구성원 관리</Menu.Item>
              {!isSpaceAdmin() &&
                !isB2B() &&
                configStore.isActivateComponent(
                  'Platform',
                  'SpaceSecession',
                ) && (
                  <Menu.Item key={TAB.SECESSION}>
                    {t('CM_SETTING_DELETE_SPACE_01')}
                  </Menu.Item>
                )}
            </Menu.ItemGroup>
          </StyledMenu>
        </SiderArea>
        <ContentArea>{getSettingContent(selectedKey)}</ContentArea>
      </LayoutWrap>
    </DialogWrap>
  );
};

export default SettingDialog;
