import React, { useCallback, useContext } from 'react';
import { useLocalStore, Observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  useCoreStores,
  ProfileInfoModal,
  logEvent,
  EventBus,
  Tooltip,
} from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  TitleWrapper,
  Title,
  TitleText,
  UserCountText,
  IconWrapper,
  SystemIconContainer,
  StyledPhotos,
  AppIconbutton,
} from './HeaderStyle';
import { useStores } from '../../stores';
import RoomInquiryModal from '../Rooms/RoomInquiryModal';
import RoomAddMemberModal from '../Rooms/RoomAddMemberModal';
import {
  ExportIcon,
  SearchIcon,
  AddAcountIcon,
  OpenChatBgIcon,
} from '../Icons';
import { getQueryParams, getQueryString } from '../../utils/UrlUtil';
import { getLeftDistance } from '../../utils/GeneralUtil';
import { getName, openMeeting } from '../../utils/RoomUtil';
import * as useCommand from '../../hook/Command';
import HeaderIconButton from './HeaderIconButton';
import {
  FirstContainer,
  SecondContainer,
  ThirdContainer,
} from './HeaderButtonContainer';
import AppHeader from './AppHeader';

const HeaderTitle = ({ appType }) => {
  const history = useHistory();
  const { uiStore } = useStores();
  const { roomStore, userStore, themeStore, notificationStore } =
    useCoreStores();
  const { t } = useTranslation();

  const store = useLocalStore(() => ({
    appConfirm: null,
    inviteRoomId: null,
    visible: {
      roomProfileModal: false,
      addMemberModal: false,
    },
  }));

  const findRoom = () => {
    if (appType !== 'f') {
      const roomId = uiStore.resourceId || roomStore.myRoom.id;
      return roomStore.getRoomMap().get(roomId);
    }
    return null;
  };

  const handleInviteUsers = async (_, resultRoomId) => {
    // 1:1 룸에 초대한 경우 새로운 룸이 생성되는데, 이 경우 그 룸으로 이동해야함.
    if (findRoom()?.id !== resultRoomId) {
      history.push(`/s/${resultRoomId}/talk`);
    }

    store.visible.addMemberModal = false;
  };

  const handleCancelInviteUsers = () => {
    store.visible.addMemberModal = false;
  };

  const openSubApp = useCallback(
    async appName => {
      const queryParams = { ...getQueryParams(), sub: appName };
      const queryString = getQueryString(queryParams);

      if (appType === 'f') {
        try {
          const response = await roomStore.getDMRoom(
            userStore.myProfile.id,
            userStore.myProfile.id,
          );
          if (!response.result) {
            throw Error('DM ROOM GET FAILED');
          }
          history.push(`/s/${response.roomInfo.id}/talk?${queryString}`);
        } catch (e) {
          console.error(`Error is${e}`);
        }
      } else {
        history.push(`${history.location.pathname}?${queryString}`);
      }
    },
    [history, roomStore, appType, userStore.myProfile.id],
  );

  const closeSubApp = () => {
    const queryParams = getQueryParams();
    delete queryParams.sub;
    const queryString = getQueryString(queryParams);

    history.push(`${history.location.pathname}?${queryString}`);
  };

  const isBotRoom = () => {
    const found = findRoom();
    return found?.isBotRoom;
  };

  const isDMRoom = () => {
    const found = findRoom();
    return !!found?.isDirectMsg;
  };
  const getRoomName = () => {
    const found = findRoom();
    return getName(found);
  };

  const isMyRoom = () => {
    const found = findRoom();
    return found?.type === 'WKS0001';
  };

  const isMeetingDisabled = () => {
    const found = findRoom();
    return found?.userCount === 1;
  };

  const getUserCount = () => {
    const found = findRoom();
    if (found && found?.userCount) {
      return found.userCount;
    }
    return null;
  };

  const handleOpenMeeting = () => {
    const roomInfo = findRoom();
    openMeeting(roomInfo);
  };

  const handleExport = () => {
    const roomInfo = findRoom();

    uiStore.openWindow({
      id: roomInfo.id,
      type: 'talk',
      name: roomInfo.name,
      userCount: roomInfo.userCount,
      handler: null,
    });
  };

  const handleSearch = () => EventBus.dispatch('Talk:OpenSearch');

  const handleCancelRoomMemeberModal = () => {
    store.visible.roomProfileModal = false;
  };

  const handleClickRoomPhoto = () => {
    const roomInfo = findRoom();
    if (isDMRoom() && roomInfo.userCount === 1) return;
    if (!isBotRoom()) store.visible.roomProfileModal = true;
  };

  const handleAppClick = async appName => {
    if (appName === 'meeting') {
      // 혼자 있는 방에서 토크의 meeting open 슬래시 커맨드 처리
      const userCount = findRoom()?.userCount;
      if (userCount && userCount === 1) return;
      handleOpenMeeting();
    } else if (appName === 'noti') uiStore.isNotificationCenterVisible = true;
    else if (uiStore.subApp !== appName) openSubApp(appName);
    else closeSubApp();

    switch (appName) {
      case 'drive':
        logEvent('gnb', 'clickTeeDriveBtn');
        break;
      case 'calendar':
        logEvent('gnb', 'clickTeeCalendarBtn');
        break;
      case 'note':
        logEvent('gnb', 'clickTeeNoteBtn');
        break;
      case 'meeting':
        logEvent('gnb', 'clickTeeMeetingBtn');
        break;
      case 'files':
        logEvent('gnb', 'clickPlusBtn');
        break;
      default:
        break;
    }
  };

  const handleNewNote = useCallback(() => {
    openSubApp('note');
    EventBus.dispatch('onSlashCreateNote');
  }, [openSubApp]);

  const handleSearchDrive = useCallback(() => {
    openSubApp('drive');
    EventBus.dispatch('Drive:Command:SearchDrive');
  }, [openSubApp]);

  const handleOpenApp = appName => () => {
    handleAppClick(appName);
  };

  const handleAddMember = useCallback(() => {
    store.visible.addMemberModal = true;
  }, [store.visible]);

  useCommand.InviteMember(handleAddMember);
  useCommand.NewNote(handleNewNote);
  useCommand.SearchDrive(handleSearchDrive);
  useCommand.OpenApp('calendar', handleOpenApp('calendar'));
  useCommand.OpenApp('drive', handleOpenApp('drive'));
  useCommand.OpenApp('note', handleOpenApp('note'));
  useCommand.OpenApp('meeting', handleOpenApp('meeting'));

  const themeContext = useContext(ThemeContext);

  const isActive = name => {
    if (name === 'meeting')
      return !!uiStore.getWindow('meeting', findRoom()?.id);
    if (name === 'noti') return uiStore.isNotificationCenterVisible;
    return uiStore.subApp === name;
  };

  const renderAppIcon = useCallback(
    ({
      name,
      tooltip,
      icons,
      isUsedInMyRoom,
      isUsedInProfile,
      isUsedInBotRoom,
    }) => {
      const props = {
        isActive: isActive(name),
        color: themeContext.HeaderIcon,
        appName: name,
        isNewAlarm:
          name === 'noti' ? notificationStore.isExistUnreadNoti : false,
        i18n: t(tooltip),
        onClick: handleAppClick,
        defaultIcon: icons.default,
        activeIcon: icons.active,
        disabledIcon: icons.disabled,
        disabled:
          (isBotRoom() && !isUsedInBotRoom) ||
          (isMyRoom() && !isUsedInMyRoom) ||
          (appType === 'f' && !isUsedInProfile) ||
          (name === 'meeting' && isMeetingDisabled()),
      };

      return (
        <AppIconbutton key={name}>
          <HeaderIconButton {...props} />
        </AppIconbutton>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleAppClick,
      isBotRoom,
      isMyRoom,
      isMeetingDisabled,
      notificationStore.isExistUnreadNoti,
      themeContext.HeaderIcon,
      appType,
    ],
  );

  const getProfileModal = () => {
    if (isMyRoom()) {
      return (
        <ProfileInfoModal
          userId={userStore.myProfile.id}
          visible={store.visible.roomProfileModal}
          onClose={handleCancelRoomMemeberModal}
          position={{ top: '3.5rem', left: getLeftDistance(0.19) }}
        />
      );
    }
    if (isDMRoom()) {
      const dmUserId = findRoom()
        .memberIdListString.split(',')
        .find(userId => userId !== userStore.myProfile.id);

      return (
        <ProfileInfoModal
          userId={dmUserId}
          visible={store.visible.roomProfileModal}
          onClose={handleCancelRoomMemeberModal}
          onClickMeeting={roomInfo => openMeeting(roomInfo)}
          position={{ top: '3.5rem', left: getLeftDistance(0.19) }}
        />
      );
    }
    return (
      store.visible.roomProfileModal && (
        <RoomInquiryModal
          roomId={findRoom()?.id}
          visible={store.visible.roomProfileModal}
          onCancel={handleCancelRoomMemeberModal}
          width="17.5rem"
          top="3.5rem"
          left={getLeftDistance(0.19)}
        />
      )
    );
  };

  return (
    <>
      <Observer>
        {() => (
          <TitleWrapper
            style={{
              width: `calc(${uiStore.sizes[0]}% + 0.25rem)`,
              paddingLeft: '0.63rem',
              minWidth: '26.25rem',
            }}
          >
            {/* 기획상 토크의 룸 목록 (appType === 's') 에서만 미니톡, 검색, 구성원 초대 버튼이 보인다.
              추후 mail 팀으로부터 header 제공받으면 아래 조건문 변경 */}
            {(appType === 's' || appType === 'mail') && (
              <Observer>
                {() => (
                  <>
                    <Title>
                      {/* 룸 사진 */}
                      <Observer>
                        {() => (
                          <StyledPhotos
                            isClickable={!isBotRoom()}
                            className="header__photo"
                            srcList={roomStore.getRoomPhoto(findRoom()?.id)}
                            onClick={handleClickRoomPhoto}
                          />
                        )}
                      </Observer>
                      {/* 오픈룸 아이콘 */}
                      <Observer>
                        {() =>
                          findRoom()?.type === 'WKS0003' && (
                            <div
                              style={{
                                display: 'flex',
                                marginRight: '0.25rem',
                              }}
                            >
                              <OpenChatBgIcon
                                width={1.125}
                                height={1.125}
                                color="rgb(0, 73, 61)"
                              />
                            </div>
                          )
                        }
                      </Observer>
                      {/* 룸 이름 */}
                      <Observer>
                        {() => <TitleText>{getRoomName()}</TitleText>}
                      </Observer>
                      {/* 유저 수 */}
                      <Observer>
                        {() =>
                          !(isMyRoom() || isDMRoom()) ? (
                            <UserCountText>{getUserCount()}</UserCountText>
                          ) : null
                        }
                      </Observer>
                      {/* 모달 */}
                      <Observer>{() => getProfileModal()}</Observer>
                    </Title>

                    <Observer>
                      {() =>
                        appType === 's' && (
                          <SystemIconContainer>
                            {uiStore.layout !== 'expand' && (
                              <>
                                <Tooltip
                                  placement="bottom"
                                  title={t('CM_TEMP_MINI_CHAT')}
                                  color={themeContext.CoreLight}
                                >
                                  <IconWrapper
                                    className="header__export-button"
                                    onClick={handleExport}
                                  >
                                    <ExportIcon
                                      width={1.25}
                                      height={1.25}
                                      color={themeStore.theme.HeaderIcon}
                                    />
                                  </IconWrapper>
                                </Tooltip>
                                <Tooltip
                                  placement="bottom"
                                  title={t('CM_ROOMTITLE_TOOLTIP_02')}
                                  color={themeContext.CoreLight}
                                >
                                  <IconWrapper
                                    className="header__search-button"
                                    onClick={handleSearch}
                                  >
                                    <SearchIcon
                                      width={1.25}
                                      height={1.25}
                                      color={themeStore.theme.HeaderIcon}
                                    />
                                  </IconWrapper>
                                </Tooltip>
                              </>
                            )}
                            <Observer>
                              {() =>
                                !isMyRoom() &&
                                !(
                                  userStore.myProfile?.isGuest ||
                                  findRoom()?.isBotRoom
                                ) && (
                                  <>
                                    <Tooltip
                                      placement="bottom"
                                      title={t('CM_ROOM_INVITE_USER')}
                                      color={themeContext.CoreLight}
                                    >
                                      <IconWrapper
                                        className="header__invite-button"
                                        onClick={handleAddMember}
                                      >
                                        <AddAcountIcon
                                          width={1.25}
                                          height={1.25}
                                          color={themeStore.theme.HeaderIcon}
                                        />
                                      </IconWrapper>
                                    </Tooltip>

                                    <Observer>
                                      {() => (
                                        <RoomAddMemberModal
                                          visible={store.visible.addMemberModal}
                                          roomId={
                                            store.inviteRoomId || findRoom()?.id
                                          }
                                          onInviteUsers={handleInviteUsers}
                                          onCancel={handleCancelInviteUsers}
                                        />
                                      )}
                                    </Observer>
                                  </>
                                )
                              }
                            </Observer>
                          </SystemIconContainer>
                        )
                      }
                    </Observer>
                  </>
                )}
              </Observer>
            )}
            {/* Header를 넣어주는 app이 사용 */}
            <AppHeader appType={appType} />
          </TitleWrapper>
        )}
      </Observer>

      <Observer>
        {() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width:
                uiStore.resourceType === 's' && uiStore.layout === 'collapse'
                  ? `calc(${uiStore.sizes[1]}% - 2.61rem)`
                  : 'unset',
              minWidth:
                uiStore.resourceType === 's' && uiStore.layout === 'collapse'
                  ? '19.65rem'
                  : '',
            }}
          >
            {(appType === 'f' || appType === 's') && (
              <FirstContainer store={store} renderAppIcon={renderAppIcon} />
            )}
            {(appType === 'f' || appType === 's' || appType === 'mail') && (
              <SecondContainer renderAppIcon={renderAppIcon} />
            )}
            {/* TODO: 알람센터 다른 앱에서도 존재하는지 */}
            <ThirdContainer renderAppIcon={renderAppIcon} />
          </div>
        )}
      </Observer>
    </>
  );
};

export default HeaderTitle;
