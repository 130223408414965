import React from 'react';

const AppHeader = ({ appType }) => {
  // TODO: mail header 제공받으면 여기에 넣기
  // TODO: app Type에 맞추어 해당하는 컴포넌트 그리기

  return null;
};

export default AppHeader;
