import { UserStore } from 'teespace-core';
import { i18n } from '../i18n';
import uiStore from '../stores/uiStore';

export const getName = roomInfo => {
  if (!roomInfo) return null;

  const { isDirectMsg, memberIdListString, userCount, type } = roomInfo;
  const {
    myProfile: { id, displayName },
  } = UserStore;

  if (type === 'WKS0001') return displayName;

  const noMembers = !!(
    type === 'WKS0002' &&
    !isDirectMsg &&
    memberIdListString === id &&
    userCount === 1
  );
  if (noMembers) return i18n.t('CM_DEL_MEMBER_03');

  if (isDirectMsg && userCount === 1) {
    if (memberIdListString === id) return i18n.t('CM_DEL_MEMBER_01');
    return i18n.t('CM_LOGIN_LOCK_01');
  }

  return roomInfo.customName || roomInfo.name;
};

// Meeting 입장 관련
export const openMeeting = roomInfo => {
  // 클릭할 때 flag의 true false가 바뀌어야 한다.
  // 처음 누를 경우에 true로 바꾸어 준다.
  const meetingFlag = uiStore.meetingFlagMap.get(roomInfo.id);
  uiStore.meetingFlagMap.set(roomInfo.id, !meetingFlag);

  const handleCallback = data => {
    if (data.server === 'old') {
      uiStore.openWindow({
        id: roomInfo.id,
        type: 'meeting',
        name: null,
        userCount: null,
        handler: null,
      });
    } else {
      uiStore.openWindow({
        id: roomInfo.id,
        type: 'meeting',
        link: data.link,
      });
    }
  };

  uiStore.openMeeting({
    start: !meetingFlag,
    roomId: roomInfo.id,
    roomName: roomInfo.customName || roomInfo.name,
    callbackFunc: handleCallback,
  });
};
