import React, { useState } from 'react';
import { Button } from 'antd';
import { Input, useCoreStores } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import ErrorIcon from '../../common/ErrorIcon';
import {
  getCompanyNumber,
  updateMyProfile,
  handlePhoneNumber,
} from '../../../utils/ProfileUtil';
import {
  InnerItem,
  Name,
  Data,
  TextArea,
  ButtonArea,
  InputArea,
} from '../../../styles/usersettings/SettingDialogStyle';

const SettingDialogCompanyNum = () => {
  const { t } = useTranslation();
  const { userStore } = useCoreStores();
  const { myProfile } = userStore;
  const [companyNum, setCompanyNum] = useState(myProfile.companyNum);
  const [isCompanyNumEdit, setIsCompanyNumEdit] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);

  const handleChangeCompanyNum = async () => {
    if (errorVisible) return;
    if (isProceeding) return;
    try {
      setIsProceeding(true);
      await updateMyProfile({ companyNum });
      setIsProceeding(false);
      setIsCompanyNumEdit(false);
    } catch (e) {
      console.log(`changeCompanyPhone Error is ${e}`);
      setIsProceeding(false);
    }
  };
  const handleCancelChange = () => {
    setIsCompanyNumEdit(false);
    setCompanyNum(myProfile.companyNum);
    if (errorVisible) setErrorVisible(false);
  };

  return (
    <InnerItem>
      <Name>{t('CM_B2B_SETTING_CHANGE_INFO_50')}</Name>
      <Data>
        <TextArea>
          {isCompanyNumEdit ? (
            <InputArea>
              <Input
                value={companyNum}
                onChange={e => {
                  const { value } = e.target;
                  handlePhoneNumber(
                    value,
                    errorVisible,
                    () => setErrorVisible(true),
                    () => setErrorVisible(false),
                    num => setCompanyNum(num),
                  );
                }}
              />
              <ErrorIcon
                visible={errorVisible}
                tooltipText="ADMIN_MANAGECORP_MANAGEORG_MEM_REGISTRATION_03"
              />
            </InputArea>
          ) : (
            <Observer>
              {() => <p>{getCompanyNumber(userStore.myProfile)}</p>}
            </Observer>
          )}
        </TextArea>
        <ButtonArea>
          {isCompanyNumEdit ? (
            <>
              <Button
                size="small"
                type="solid"
                disabled={myProfile.companyNum === companyNum}
                onClick={handleChangeCompanyNum}
              >
                {t('CM_SAVE')}
              </Button>
              <Button size="small" type="outlined" onClick={handleCancelChange}>
                {t('CM_CANCEL')}
              </Button>
            </>
          ) : (
            <Button
              size="small"
              type="outlined"
              onClick={() => setIsCompanyNumEdit(true)}
            >
              {t('CM_CHANGE')}
            </Button>
          )}
        </ButtonArea>
      </Data>
    </InnerItem>
  );
};

export default React.memo(SettingDialogCompanyNum);
