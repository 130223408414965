import React, { useState, useCallback, useContext } from 'react';
import { Tooltip, AddFriendsBySearch, ThemeStore } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { MemberIcon } from '../Icons';
import { AppIconInner } from './HeaderStyle';

const MemberButton = React.memo(({ onOpen }) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="bottom"
      title={t('CM_USER_LIST')}
      color={themeContext.CoreLight}
      clickDisappear
    >
      <AppIconInner className="header__member-button" onClick={onOpen}>
        <MemberIcon color={ThemeStore.theme.HeaderIcon} />
      </AppIconInner>
    </Tooltip>
  );
});

const MemberViewItem = () => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <MemberButton onOpen={handleOpen} />
      {isOpen && (
        <AddFriendsBySearch
          isViewMode
          onCancelAddFriends={handleClose}
          isTopOrg={false}
          isMeVisible
        />
      )}
    </>
  );
};

export default MemberViewItem;
