import styled from 'styled-components';
import { Layout } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const FriendsLnbWrapper = styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${props => props.theme.StateNormal};
`;
