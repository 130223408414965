import React from 'react';
import { Wrapper, HeaderProfileContainer } from './HeaderStyle';
import HeaderProfile from '../profile/HeaderProfile';
import HeaderTitle from './HeaderTitle';

const Header = ({ appType }) => {
  return (
    <Wrapper>
      <HeaderTitle appType={appType} />
      <HeaderProfileContainer>
        <HeaderProfile />
      </HeaderProfileContainer>
    </Wrapper>
  );
};

export default Header;
