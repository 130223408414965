import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Observer } from 'mobx-react';
import {
  useCoreStores,
  Checkbox,
  UserStore,
  logEvent,
  AddFriendsByInvitationDialog,
  AddFriendsBySearch,
  Toast,
  EventBus,
} from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ThemeContext } from 'styled-components';
import * as S from './LeftSideGroupStyle';
import { ArrowDownIcon } from '../Icons';
import { getMainWaplURL } from '../../utils/UrlUtil';
import { isSpaceAdmin, isStandAlone } from '../../utils/GeneralUtil';
import * as useCommand from '../../hook/Command';
import GroupEditModal from '../profile/group/GroupEditModal';
import GroupModal from './GroupModal';
import SelectRoomTypeDialog from '../Rooms/SelectRoomTypeDialog';

const GroupThumbNail = ({
  groupPhoto = null,
  title,
  size = '2.25rem',
  fontSize = '0.75rem',
  borderRadius = '0.5rem',
  border,
  bright,
}) => {
  return (
    <S.ThumbNail
      size={size}
      fontSize={fontSize}
      borderRadius={borderRadius}
      border={border}
      bright={bright}
    >
      {groupPhoto ? <S.ThumbPhoto alt="groupImg" src={groupPhoto} /> : title}
    </S.ThumbNail>
  );
};

const LeftSideGroup = ({ created = false }) => {
  const { spaceStore, configStore, userStore } = useCoreStores();
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [invitePeople, setInvitePeople] = useState([]);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isGroupEditModalVisible, setIsGroupEditModalVisible] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);
  const [isFriendMemViewOpen, setIsFriendMemViewOpen] = useState(false);
  const [isRoomDialogVisible, setIsRoomDialogVisible] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastText, setToastText] = useState('');
  const [isCreated, setIsCreated] = useState(created);
  const { isGuest } = userStore.myProfile;
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const inviteUserHandler = EventBus.on('Platform:inviteUser', () => {
      setIsInviteDialogOpen(true);
    });

    return () => EventBus.off('Platform:inviteUser', inviteUserHandler);
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      spaceStore.fetchSpaces({
        userId: userStore.myProfile.id,
        isLocal: process.env.REACT_APP_ENV === 'local',
      })
    }
  }, [isDropdownOpen])

  const isDimmedClick = e => {
    if (e.target === e.currentTarget) {
      setIsDropdownOpen(false);
      setIsCreated(false);
    }
  };

  const handleNewSpace = () => {
    const adminSpace = spaceStore.getAdminSpaces({
      loginId: UserStore.myProfile.loginId,
    });
    if (adminSpace.length >= 3) {
      Modal.info({
        mask: false,
        centered: true,
        width: '22.5rem',
        title: t('CM_SPACE_CREATE_OPTION_ERROR_03'),
        okText: t('CM_LOGIN_POLICY_03'),
      });
    } else window.location.href = getMainWaplURL('/select-space-type');
  };

  const getGroupTitle = space => {
    return (
      <>
        <GroupThumbNail
          groupPhoto={space?.profilePhotoURL}
          title={space?.name[0]}
          size="1.25rem"
          borderRadius="0.313rem"
        />
        <S.SelectedTitle>{space?.name}</S.SelectedTitle>
      </>
    );
  };

  const handleAddFriend = useCallback(async () => {
    setIsViewMode(false);
    setIsFriendMemViewOpen(true);
  }, []);

  useEffect(() => {
    const inviteUserHandler = EventBus.on('Platform:inviteUser', () => {
      setIsInviteDialogOpen(true);
    });

    return () => EventBus.off('Platform:inviteUser', inviteUserHandler);
  }, []);

  // OptionArea
  const handleOpenInviteModal = useCallback(
    (mailAddr = []) =>
      () => {
        setInvitePeople(mailAddr);
        setIsInviteDialogOpen(true);
        logEvent('threedot', 'clickInviteMemberBtn');
      },
    [],
  );

  const handleCancelInviteModal = useCallback(() => {
    setIsInviteDialogOpen(false);
  }, []);

  const handleOpenMemberModal = useCallback(async () => {
    setIsViewMode(true);
    setIsFriendMemViewOpen(true);
    logEvent('threedot', 'clickShowMemberList');
  }, []);

  const handleOpenGroupEditModal = useCallback(() => {
    setIsGroupEditModalVisible(true);
  }, []);

  const handleMoveAdminPage = useCallback(() => {
    window.open(`${window.location.origin}/admin`);
  }, []);

  const isTmaxDomain = !!/^(tmax)\./gi.exec(window.location.hostname);

  useCommand.InvitePeople(handleOpenInviteModal);

  useEffect(() => {
    setIsCreated(created);
    // 어드민이 그룹 초기 진입 시 dropdown 열린 상태로 표시
    setIsDropdownOpen(created && isSpaceAdmin());
  }, [created]);

  // 설치형(isStandAlone) 정책: 아래 arrow가 없고, 클릭시 반응 없음
  return (
    <Observer>
      {() => {
        const isAdmin =
          userStore.myProfile.grade === 'admin' ||
          userStore.myProfile.grade === 'superadmin';

        return (
          <S.Wrapper>
            {isStandAlone() ? (
              <S.GroupSelector style={{ cursor: 'auto' }}>
                {getGroupTitle(spaceStore.currentSpace)}
              </S.GroupSelector>
            ) : (
              <S.GroupSelector
                onClick={() => setIsDropdownOpen(true)}
                // 멤버가 그룹 초기 진입 시 그룹탭의 z-index가 상위
                style={{ zIndex: isCreated && !isAdmin ? '205' : null }}
              >
                {getGroupTitle(spaceStore.currentSpace)}
                <ArrowDownIcon color={themeContext.StateNormal} />
              </S.GroupSelector>
            )}
            {(isDropdownOpen || isCreated) && (
              <S.GroupDimmedWrap onClick={isDimmedClick}>
                {isDropdownOpen && (
                  <S.GroupDropdown>
                    <S.GroupArea>
                      <GroupThumbNail
                        groupPhoto={spaceStore.currentSpace?.profilePhotoURL}
                        title={spaceStore.currentSpace?.name[0]}
                        size="2.625rem"
                        fontSize="0.938rem"
                        border
                      />
                      <S.GroupInfo>
                        <S.InfoTitle>
                          {spaceStore.currentSpace?.name}
                        </S.InfoTitle>
                        <S.InfoDomain>
                          {spaceStore.currentSpace?.domain}
                        </S.InfoDomain>
                      </S.GroupInfo>
                      <Checkbox className="check-round" checked />
                    </S.GroupArea>
                    <S.OptionArea>
                      {!isTmaxDomain && !isGuest && !configStore.isFromCNU && (
                        <S.OptionItem onClick={handleOpenInviteModal()}>
                          {t('CM_USER_INVITE')}
                        </S.OptionItem>
                      )}
                      <S.OptionItem onClick={handleOpenMemberModal}>
                        {t('CM_USER_LIST')}
                      </S.OptionItem>
                      {isAdmin && !configStore.isFromCNU && (
                        <S.OptionItem onClick={handleOpenGroupEditModal}>
                          {t('CM_SPACE_EDIT')}
                        </S.OptionItem>
                      )}
                      {isAdmin && (
                        <S.OptionItem onClick={handleMoveAdminPage}>
                          {t('CM_ADMIN_PAGE')}
                        </S.OptionItem>
                      )}
                    </S.OptionArea>
                    <S.GroupControlArea>
                      <S.ControlBox onClick={handleNewSpace}>
                        <GroupThumbNail title="+" bright />
                        <S.ControlAddText>
                          {t('CM_CREATE_CONTENTS_AREA_02')}
                        </S.ControlAddText>
                      </S.ControlBox>
                      {spaceStore.spaceList?.map(space => {
                        if (space.id === spaceStore.currentSpace.id)
                          return null;
                        return (
                          <S.ControlBox
                            key={space.id}
                            onClick={() => {
                              window.location.href = `${window.location.protocol}//${space.domain}`;
                            }}
                          >
                            <GroupThumbNail
                              groupPhoto={space.profilePhotoURL}
                              title={space.name[0]}
                            />
                            <S.ControlText>{space.name}</S.ControlText>
                            {space.unreadSpaceCount > 0 && (
                              <S.NewBadge>{space.unreadSpaceCount}</S.NewBadge>
                            )}
                          </S.ControlBox>
                        );
                      })}
                    </S.GroupControlArea>
                  </S.GroupDropdown>
                )}
                {isCreated && (
                  <GroupModal
                    userName={UserStore.myProfile.displayName}
                    onInvite={() => {
                      setIsInviteDialogOpen(true);
                    }}
                    onRoomCreate={() => setIsRoomDialogVisible(true)}
                    onAddFriend={handleAddFriend}
                    onClose={() => setIsCreated(false)}
                  />
                )}
              </S.GroupDimmedWrap>
            )}
            {isInviteDialogOpen && (
              <AddFriendsByInvitationDialog
                visible={isInviteDialogOpen}
                mailList={invitePeople}
                onCancel={handleCancelInviteModal}
              />
            )}
            {isFriendMemViewOpen && (
              <AddFriendsBySearch
                isViewMode={isViewMode}
                onCancelAddFriends={() => setIsFriendMemViewOpen(false)}
                isTopOrg={false}
                isMeVisible={isViewMode} // 구성원 보기일때 true
              />
            )}
            {isGroupEditModalVisible && (
              <GroupEditModal
                onClose={() => setIsGroupEditModalVisible(false)}
                onSuccess={() => {
                  setToastText(t('CM_CHANGE_SAVE'));
                  setIsToastOpen(true);
                }}
              />
            )}
            {isRoomDialogVisible && (
              <SelectRoomTypeDialog
                visible={isRoomDialogVisible}
                onCancel={() => setIsRoomDialogVisible(false)}
                onCreateRoom={({ selectedUsers, isNewRoom }) => {
                  if (isNewRoom) {
                    setToastText(
                      t('CM_INVITE_MEMBER', {
                        num: selectedUsers.length,
                      }),
                    );
                    setIsToastOpen(true);
                  }
                }}
              />
            )}
            <Toast
              visible={isToastOpen}
              timeoutMs={1000}
              onClose={() => setIsToastOpen(false)}
            >
              {toastText}
            </Toast>
          </S.Wrapper>
        );
      }}
    </Observer>
  );
};

export default LeftSideGroup;
