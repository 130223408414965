import React from 'react';
import { Observer } from 'mobx-react';
import { ThemeStore, useCoreStores } from 'teespace-core';
import MemberViewItem from './MemberViewItem';
import { AppIconContainer } from './HeaderStyle';
import {
  NoteIcon,
  NoteActiveIcon,
  DriveIcon,
  DriveActiveIcon,
  CalendarIcon,
  CalendarActiveIcon,
  ViewFileIcon,
  ViewFileActiveIcon,
  MeetingIcon,
  MeetingActiveIcon,
  AlarmOnActiveIcon,
  AlarmOnIcon,
} from '../Icons';

const getIconStyle = (isDisabled = false) => {
  return {
    width: 1.38,
    height: 1.38,
    color: isDisabled
      ? ThemeStore.theme.IconDisabled
      : ThemeStore.theme.HeaderIcon,
  };
};

const firstApps = [
  {
    name: 'drive',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_18',
    icons: {
      active: <DriveActiveIcon {...getIconStyle()} />,
      disabled: <DriveIcon {...getIconStyle(true)} />,
      default: <DriveIcon {...getIconStyle()} />,
    },
    isUsedInBotRoom: false,
    isUsedInMyRoom: true,
    isUsedInProfile: true,
  },
  {
    name: 'calendar',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_17',
    icons: {
      active: <CalendarActiveIcon {...getIconStyle()} />,
      disabled: <CalendarIcon {...getIconStyle(true)} />,
      default: <CalendarIcon {...getIconStyle()} />,
    },
    isUsedInBotRoom: false,
    isUsedInMyRoom: true,
    isUsedInProfile: true,
  },
  {
    name: 'note',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_19',
    icons: {
      active: <NoteActiveIcon />,
      disabled: <NoteIcon />,
      default: <NoteIcon />,
    },
    isUsedInBotRoom: false,
    isUsedInMyRoom: true,
    isUsedInProfile: true,
  },
  {
    name: 'meeting',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_20',
    icons: {
      active: <MeetingActiveIcon />,
      disabled: <MeetingIcon />,
      default: <MeetingIcon />,
    },
    isUsedInBotRoom: false,
    isUsedInMyRoom: false,
    isUsedInProfile: false,
  },
];

const secondApps = [
  {
    name: 'files',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_16',
    icons: {
      active: <ViewFileActiveIcon />,
      disabled: <ViewFileIcon />,
      default: <ViewFileIcon />,
    },
    isUsedInBotRoom: false,
    isUsedInMyRoom: true,
    isUsedInProfile: true,
  },
];

const thirdApps = [
  {
    name: 'noti',
    tooltip: 'CM_B2C_CONTENTS_AREA_EMPTY_PAGE_29',
    icons: {
      active: <AlarmOnActiveIcon />,
      disabled: <AlarmOnIcon />,
      default: <AlarmOnIcon />,
    },
    isUsedInBotRoom: true,
    isUsedInMyRoom: true,
    isUsedInProfile: true,
  },
];

const FirstContainer = React.memo(({ store, renderAppIcon }) => {
  const { configStore } = useCoreStores();
  return (
    <AppIconContainer>
      <Observer>{() => store.appConfirm}</Observer>
      <Observer>
        {() =>
          firstApps.map(
            ({
              name,
              tooltip,
              icons,
              isUsedInMyRoom,
              isUsedInProfile,
              isUsedInBotRoom,
            }) =>
              configStore.isActivateForCNU(
                `${name.charAt(0).toUpperCase()}${name.slice(1, name.length)}`,
              )
                ? renderAppIcon({
                    name,
                    tooltip,
                    icons,
                    isUsedInMyRoom,
                    isUsedInProfile,
                    isUsedInBotRoom,
                  })
                : null,
          )
        }
      </Observer>
    </AppIconContainer>
  );
});

const SecondContainer = React.memo(({ renderAppIcon }) => {
  return (
    <AppIconContainer>
      <Observer>
        {() =>
          secondApps.map(
            ({
              name,
              tooltip,
              icons,
              isUsedInMyRoom,
              isUsedInProfile,
              isUsedInBotRoom,
            }) =>
              renderAppIcon({
                name,
                tooltip,
                icons,
                isUsedInMyRoom,
                isUsedInProfile,
                isUsedInBotRoom,
              }),
          )
        }
      </Observer>
    </AppIconContainer>
  );
});

const ThirdContainer = React.memo(({ renderAppIcon }) => {
  return (
    <AppIconContainer>
      <Observer>
        {() =>
          thirdApps.map(
            ({
              name,
              tooltip,
              icons,
              isUsedInMyRoom,
              isUsedInProfile,
              isUsedInBotRoom,
            }) =>
              renderAppIcon({
                name,
                tooltip,
                icons,
                isUsedInMyRoom,
                isUsedInProfile,
                isUsedInBotRoom,
              }),
          )
        }
      </Observer>
      <MemberViewItem />
    </AppIconContainer>
  );
});

export { FirstContainer, SecondContainer, ThirdContainer };
