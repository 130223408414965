import React, { useContext } from 'react';
import { Tooltip, ThemeStore } from 'teespace-core';
import { ThemeContext } from 'styled-components';
import { AppIconInner, NewAlarm } from './HeaderStyle';

const getIconStyle = (isDisabled = false) => {
  return {
    width: 1.38,
    height: 1.38,
    color: isDisabled
      ? ThemeStore.theme.IconDisabled
      : ThemeStore.theme.HeaderIcon,
  };
};

const HeaderIconButton = React.memo(
  ({
    isActive,
    isNewAlarm = false,
    appName,
    i18n,
    onClick,
    defaultIcon,
    activeIcon,
    disabledIcon,
    disabled,
  }) => {
    const themeContext = useContext(ThemeContext);
    const handleAppClick = () => {
      onClick(appName);
    };

    let icon = defaultIcon;
    if (disabled) {
      icon = React.cloneElement(disabledIcon, { ...getIconStyle(true) });
    } else {
      icon = React.cloneElement(isActive ? activeIcon : defaultIcon, {
        ...getIconStyle(),
      });
    }

    return (
      <Tooltip
        placement="bottom"
        title={i18n}
        color={themeContext.CoreLight}
        clickDisappear
      >
        <AppIconInner
          className={`header__${appName}-button`}
          key={appName}
          onClick={handleAppClick}
          disabled={disabled}
        >
          {icon}
          {isNewAlarm ? <NewAlarm /> : null}
        </AppIconInner>
      </Tooltip>
    );
  },
);
HeaderIconButton.displayName = 'AppIcon';

export default HeaderIconButton;
