// 앱 채널 타입
export const CHANNEL_TYPE = Object.freeze({
  TALK: 'CHN0001',
  NOTE: 'CHN0003',
  CALENDAR: 'CHN0005',
  MEETING: 'CHN0005',
  DRIVE: 'CHN0006',
});

// 설정 TAB 정보
export const SELECTED_TAB = Object.freeze({
  ACCOUNT: '1',
  LANGUAGE: '2',
  THEME: '3',
  PROFILE: '4',
  ALARM: '5',
  SECESSION: '6',
  BLOCKED: '7',
});

// 설정: 알림 조회 및 변경
export const ALARM_TYPE = Object.freeze({
  DESKTOP: 'desktop_all',
  SOUND: 'alarm_sound',
  TALK: 'ttalk_all',
  TALK_CONTENTS: 'ttalk_showContents',
  TALK_MENTION: 'ttalk_mention',
  MEETING: 'cloudmeeting_all',
  MEETING_START: 'cloudmeeting_meetingstart',
  MEETING_END: 'cloudmeeting_meetingend',
  MAIL: 'tmail_all',
  CALENDAR: 'tschedule_all',
});
export const ALARM_TYPE_SEND = Object.freeze({
  DESKTOP: 'desktop_all',
  SOUND: 'alarm_sound',
  TALK: 'talk_all',
  TALK_CONTENTS: 'talk_showContents',
  TALK_MENTION: 'talk_mention',
  MEETING: 'meeting_all',
  MEETING_START: 'meeting_start',
  MEETING_END: 'meeting_end',
  MAIL: 'mail_all',
  CALENDAR: 'schedule_all',
});

// 드래그 앤 드롭
export const ACCEPT_ITEMS = [
  'Item:Drive:Files',
  'Item:Note:Pages',
  'Item:Note:SharedPages',
  'Item:Note:Chapters',
  'Item:Note:SharedChapters',
  'Item:Calendar:ShareSchedules',
];
export const TALK_ACCEPT_ITEMS = [
  'Item:Calendar:ShareSchedules',
  'Item:Drive:Files',
];
