import React, { useState, useRef } from 'react';
import { useCoreStores, Input } from 'teespace-core';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import ContentTitle from './ContentTitle';
import {
  ContentDataWrap,
  ContentGroup,
  GroupTitle,
  GroupTitleBlack,
  NoticeList,
  NoticeItem,
  SpaceBox,
  SpaceLogo,
  SpaceTitle,
  SpaceInfo,
  CheckboxWrap,
  StyledButton,
  InputWrap,
  InputName,
} from '../../styles/usersettings/ContentSpaceSecessionStyle';

const SpaceSecessionUpper = React.memo(({ spaceName, spaceDomain }) => {
  const { t } = useTranslation();
  return (
    <ContentGroup>
      <GroupTitle>{t('CM_SETTING_DELETE_SPACE_03')}</GroupTitle>
      <SpaceBox>
        <SpaceLogo
          shape="square"
          style={{ color: '#fff', backgroundColor: '#75757F' }}
        >
          {spaceName[0]}
        </SpaceLogo>
        <SpaceInfo>
          <SpaceTitle>{spaceName}</SpaceTitle>
          {spaceDomain}
        </SpaceInfo>
      </SpaceBox>
    </ContentGroup>
  );
});

const SpaceSecessionText = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      <GroupTitle>{t('CM_SETTING_DELETE_SPACE_04')}</GroupTitle>
      <NoticeList>
        <NoticeItem>{t('CM_SETTING_DELETE_SPACE_05')}</NoticeItem>
        <NoticeItem>{t('CM_SETTING_DELETE_SPACE_06')}</NoticeItem>
      </NoticeList>
    </>
  );
});

const ContentSpaceSecession = ({
  isSecession,
  toggleSecession,
  onInputChange,
}) => {
  const { t } = useTranslation();
  const { spaceStore } = useCoreStores();
  const [checked, setChecked] = useState(false);
  const inputRef = useRef(null);

  const handleToggleSecession = () => {
    toggleSecession();
    setChecked(false);
    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 1);
  };

  return (
    <>
      <ContentTitle
        title={t('CM_SETTING_DELETE_SPACE_01')}
        subTitle={t('CM_SETTING_DELETE_SPACE_02')}
      />
      <ContentDataWrap>
        <SpaceSecessionUpper
          spaceName={spaceStore.currentSpace?.name}
          spaceDomain={spaceStore.currentSpace?.domain}
        />
        <ContentGroup>
          {isSecession ? (
            <>
              <GroupTitleBlack>
                {t('CM_SETTING_DELETE_SPACE_08')}
              </GroupTitleBlack>
              <InputWrap>
                <InputName htmlFor="password">{t('CM_PWD')}</InputName>
                <Input
                  id="password"
                  type="password"
                  onChange={e => onInputChange(e.target.value)}
                  ref={inputRef}
                />
              </InputWrap>
            </>
          ) : (
            <>
              <SpaceSecessionText />
              <CheckboxWrap>
                <Checkbox
                  className="check-round"
                  onChange={() => setChecked(!checked)}
                >
                  {t('CM_ALL_READ_AGREE_POLICY')}
                </Checkbox>
              </CheckboxWrap>
              <StyledButton
                type={checked ? 'solid' : 'outlined'}
                disabled={!checked}
                onClick={handleToggleSecession}
              >
                {t('CM_SETTING_DELETE_SPACE_07')}
              </StyledButton>
            </>
          )}
        </ContentGroup>
      </ContentDataWrap>
    </>
  );
};

export default ContentSpaceSecession;
